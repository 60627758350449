import React, { useState, useEffect } from 'react';
import { Box, Container, Heading, Input, VStack, SimpleGrid, useToast } from '@chakra-ui/react';
import axios from 'axios';
import API_BASE_URL from '../config';
import { getAuth } from 'firebase/auth';
import TaskSummary from './TaskSummary';
import UserFeed from './UserFeed';
import LoadingSpinner from './LoadingSpinner';

const Tasks = () => {
  const [crews, setCrews] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    fetchCrews();
  }, []);

  const fetchCrews = async () => {
    setLoading(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) return;

      const idToken = await user.getIdToken();
      const response = await axios.get(`${API_BASE_URL}/crews`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setCrews(response.data);
    } catch (error) {
      console.error('Error fetching crews:', error);
    } finally {
      setLoading(false);
    }
  };

  const executeTask = async (crewId, taskId) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) return;

      const idToken = await user.getIdToken();
      await axios.post(`${API_BASE_URL}/crews/${crewId}/execute/${taskId}`, {}, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      toast({
        title: 'Task execution started.',
        description: 'The task is now executing.',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      fetchCrews(); // Refresh the crews data
    } catch (error) {
      console.error('Error starting task execution:', error);
      toast({
        title: 'Error',
        description: 'Failed to start task execution.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const deleteTask = async (crewId, taskId) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) return;

      const idToken = await user.getIdToken();
      await axios.delete(`${API_BASE_URL}/tasks/${taskId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      toast({
        title: 'Task deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchCrews(); // Refresh the crews data
    } catch (error) {
      console.error('Error deleting task:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete task',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const filteredCrews = crews.map(crew => ({
    ...crew,
    tasks: crew.tasks.filter(task =>
      task.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (task.result && task.result.toLowerCase().includes(searchTerm.toLowerCase()))
    )
  })).filter(crew => crew.tasks.length > 0);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxW="container.xl" mt={8} pb={16}>
      <Input
        placeholder="Search tasks..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        mb={6}
        borderRadius="full"
      />
      {searchTerm ? (
        <SimpleGrid columns={[1, 2, 3]} spacing={6}>
          {filteredCrews.map(crew => (
            <TaskSummary 
              key={crew.id} 
              crew={crew} 
              tasks={crew.tasks} 
              executeTask={(taskId) => executeTask(crew.id, taskId)}
              deleteTask={(taskId) => deleteTask(crew.id, taskId)}
            />
          ))}
        </SimpleGrid>
      ) : (
        <UserFeed />
      )}
    </Container>
  );
};

export default Tasks;