import React from 'react';
import { Box, Heading, VStack, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import TaskCard from './TaskCard';
import TaskDetailsModal from './TaskDetailsModal';

const TaskSummary = ({ crew, tasks, executeTask, deleteTask }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTask, setSelectedTask] = React.useState(null);

  const handleTaskClick = (task) => {
    if (task.status === 2) { // Assuming 2 is the status for completed tasks
      navigate(`/crews/${crew.id}/tasks/${task.id}`);
    } else {
      setSelectedTask(task);
      onOpen();
    }
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" p={4}>
      <Heading size="md" mb={2}>{crew.name}</Heading>
      <VStack align="stretch" spacing={2}>
        {tasks.slice(0, 3).map(task => (
          <TaskCard
            key={task.id}
            task={task}
            onClick={handleTaskClick}
            executeTask={() => executeTask(task.id)}
            deleteTask={() => deleteTask(task.id)}
          />
        ))}
      </VStack>
      <TaskDetailsModal
        isOpen={isOpen}
        onClose={onClose}
        task={selectedTask}
        executeTask={executeTask}
      />
    </Box>
  );
};

export default TaskSummary;