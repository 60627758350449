import React from 'react';
import { Box, Text, Flex, useColorModeValue, IconButton } from '@chakra-ui/react';
import { MdSearch, MdClose } from 'react-icons/md';

const MinionCard = ({ agent, onEdit, toggleWebSearch, onDelete, isEditable, isSelectable }) => {
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      borderColor={borderColor}
      bg={bgColor}
      p={4}
      boxShadow="sm"
      transition="all 0.2s"
      _hover={{ boxShadow: 'md' }}
      width="100%"
      position="relative"
    >
      {isEditable && (
        <Box
          position="absolute"
          top={2}
          right={2}
          opacity={1}
          transition="opacity 0.2s"
          _groupHover={{ opacity: 1 }}
        >
          <IconButton
            icon={<MdClose />}
            size="sm"
            variant="ghost"
            colorScheme="red"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(agent.id);
            }}
            aria-label="Delete minion"
          />
        </Box>
      )}

      <Box
        onClick={() => isSelectable && onEdit(agent)}
        cursor={isEditable ? "pointer" : "default"}
      >
        <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
          <Text fontWeight="bold" fontSize="lg" color={textColor}>
            {agent.role}
          </Text>
        </Flex>
        
        <Text fontSize="sm" color="gray.500" mb={3}>
          <Text as="strong">Goal</Text>
          <Text>{agent.goal}</Text>
        </Text>
        
        {agent.backstory && (
          <Text fontSize="sm" color="gray.500" mb={3}>
            <Text as="strong">Backstory</Text>
            <Text>{agent.backstory}</Text>
          </Text>
        )}
        
        <Flex 
          alignItems="center" 
          color={agent.web_search_enabled ? "teal.500" : "orange.500"}
        >
          <Box>
            <Flex 
              alignItems="center" 
              cursor={isEditable ? "pointer" : "default"}
              onClick={(e) => {
                if (isEditable) {
                  e.stopPropagation();
                  toggleWebSearch(agent.id, !agent.web_search_enabled);
                }
              }}
              _hover={isEditable ? { fontWeight: "bold" } : {}}
            >
              <MdSearch size={16} />
              <Text fontSize="xs" ml={1}>
                {agent.web_search_enabled ? "Web search enabled" : "Web search not enabled"}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default MinionCard;