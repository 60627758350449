import React from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Diff, Hunk, parseDiff, markEdits } from 'react-diff-view';
import 'react-diff-view/style/index.css';

export const DiffModal = ({ originalText, expandedText, onAccept, onDecline }) => {
  const diffText = `diff --git a/original b/expanded
--- a/original
+++ b/expanded
@@ -1 +1 @@
-${originalText}
+${expandedText}
`;

  const files = parseDiff(diffText);

  return (
    <Modal isOpen={true} onClose={onDecline} size="6xl">
      <ModalOverlay />
      <ModalContent maxWidth="90vw">
        <ModalHeader>AI Edits</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="stretch" spacing={4}>
            <Box overflowX="auto">
              {files.map(({ oldPath, newPath, hunks, type }) => (
                <Diff
                  key={oldPath + '-' + newPath}
                  viewType={window.innerWidth < 768 ? "unified" : "split"}
                  diffType={type}
                  hunks={hunks}
                  enhancers={[markEdits(hunks)]}
                >
                  {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
                </Diff>
              ))}
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={onAccept}>
            Accept
          </Button>
          <Button variant="ghost" onClick={onDecline}>
            Decline
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};