import React, { createContext, useContext, useState, useCallback } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import API_BASE_URL from '../config';

const CrewsContext = createContext();

export function CrewsProvider({ children }) {
  const [crews, setCrews] = useState([]);

  const fetchCrews = useCallback(async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }

      const idToken = await user.getIdToken();
      const response = await axios.get(`${API_BASE_URL}/crews`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setCrews(response.data);
    } catch (error) {
      console.error('Error fetching crews:', error);
    }
  }, []);

  return (
    <CrewsContext.Provider value={{ crews, fetchCrews }}>
      {children}
    </CrewsContext.Provider>
  );
}

export function useCrews() {
  return useContext(CrewsContext);
}
