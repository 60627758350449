import React, { useState } from 'react';
import {
  Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
  VStack, Box, Heading, Flex, Switch, Text, InputGroup, Input, InputRightElement, Button, Textarea,
  IconButton, Tooltip
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

function CrewSettingsDrawer({ isOpen, onClose, crew, togglePublicStatus, updateCrewDetails }) {
  const [crewName, setCrewName] = useState(crew.name);
  const [crewDescription, setCrewDescription] = useState(crew.description || '');

  const handleNameChange = (e) => {
    setCrewName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setCrewDescription(e.target.value);
  };

  const isChanged = crewName !== crew.name || crewDescription !== (crew.description || '');
  const isValid = crewName.trim() !== '' && isChanged;

  const handleSave = async () => {
    try {
      await updateCrewDetails({ name: crewName, description: crewDescription });
      onClose();
    } catch (error) {
      console.error('Error updating crew details:', error);
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Team Settings</DrawerHeader>

        <DrawerBody>
          <VStack spacing={6} align="stretch">
            <Box>
              <Heading size="sm" mb={2}>Name</Heading>
              <Input value={crewName} onChange={handleNameChange} />
            </Box>

            <Box>
              <Heading size="sm" mb={2}>Description</Heading>
              <Textarea 
                value={crewDescription} 
                onChange={handleDescriptionChange}
                placeholder="Add a team description"
              />
            </Box>

            <Box>
              <Heading size="sm" mb={2}>Visibility</Heading>
              <Flex alignItems="center" justifyContent="space-between">
                <Text>{crew.is_public ? 'Public' : 'Private'}</Text>
                <Switch
                  isChecked={crew.is_public}
                  onChange={togglePublicStatus}
                  colorScheme="purple"
                />
              </Flex>
              <Text fontSize="sm" color="gray.500" mt={1}>
                {crew.is_public 
                  ? 'Anyone can find and copy this team' 
                  : 'Your team is private'}
              </Text>
            </Box>
          </VStack>
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button variant="ghost" mr={3} onClick={onClose} borderRadius="full">
            Cancel
          </Button>
          <Button 
            onClick={handleSave}
            borderRadius="full"
            bg="purple.100"
            _hover={{ bg: "purple.200" }}
            isDisabled={!isValid}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default CrewSettingsDrawer;