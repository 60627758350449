import React, { useState, useEffect } from 'react';
import { Box, Image, Text, VStack, Skeleton, useDisclosure, AspectRatio, Flex, Avatar } from '@chakra-ui/react';
import ReadingTimeCapsule from './ReadingTimeCapsule';

function TaskResultCard({ task, crew, isSelected, onSelect, feedMode }) {
  const [imageLoaded, setImageLoaded] = useState(true);
  const [imageUrl, setImageUrl] = useState(task.thumbnail_url);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setImageLoaded(false);
    setImageUrl(task.thumbnail_url);
  }, [task.thumbnail_url]);

  const parseHeadline = (result) => {
    const lines = result.split('\n');
    // Find the first line that starts with one or more '#' characters
    const headingLine = lines.find(line => /^#+\s/.test(line));
    if (headingLine) {
      return headingLine.replace(/^#+\s*/, '').trim();
    }
    // Fallback: Find the first line with bold formatting
    const boldLine = lines.find(line => /^\*\*.*\*\*$/.test(line));
    if (boldLine) {
      return boldLine.replace(/^\*\*|\*\*$/g, '').trim();
    }
    // If no heading or bold line found, use the first 100 characters
    return result.replace(/^-+\s*/, '').slice(0, 100).trim() || 'No headline found';
  };

  const headline = parseHeadline(task.result).replace(/^\*+|\*+$/g, '').replace(/^_+|_+$/g, '');

  const renderImage = () => {
    if (!imageUrl) {
      return (
        <AspectRatio ratio={16 / 9} width="100%" minHeight="150px" maxHeight="250px">
          <Box bgGradient="linear(to-r, pink.100, blue.100)" />
        </AspectRatio>
      );
    }

    return (
      <AspectRatio ratio={16 / 9} width="100%" minHeight="150px" maxHeight="250px">
        <Skeleton isLoaded={imageLoaded}>
          <Image 
            src={imageUrl} 
            alt={headline} 
            objectFit="cover"
            width="100%" 
            height="100%"
            onLoad={() => setImageLoaded(true)}
            onError={() => setImageLoaded(true)}
          />
        </Skeleton>
      </AspectRatio>
    );
  };

  return (
    <Box 
      borderWidth={isSelected ? 2 : 1}
      borderColor={isSelected ? "purple.500" : "gray.200"}
      borderRadius="lg" 
      overflow="hidden" 
      boxShadow={isSelected ? "md" : "sm"}
      position="relative"
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
      onClick={onSelect}
      width="100%"
      maxWidth="100%"
      bg="gray.700"
      transition="all 0.3s ease-in-out"
      _hover={{ 
        boxShadow: "xl",
        transform: "translateY(-5px) scale(1.02)",
      }}
    >
      {renderImage()}
      
      <VStack 
        p={4} 
        align="start" 
        bg="gray.700" 
        minHeight="60px"
        width="100%" 
        justify="flex-start"
        spacing={2}
      >
        <Text fontSize="md" fontWeight="bold" color="gray.200">
          {headline}
        </Text>
        <ReadingTimeCapsule text={task.result} />
      </VStack>
    </Box>
  );
}

export default TaskResultCard;