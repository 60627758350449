import React from 'react';
import { ChakraProvider, Box, Container, Flex, Spacer, Text, Button, Input, Image, VStack, useToast } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, Switch } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import CrewList from './components/CrewList';
import CrewForm from './components/CrewForm';
import CrewDetails from './components/CrewDetails';
import ShareableTaskDetails from './components/ShareableTaskDetails';
import FullScreenTaskDetails from './components/FullScreenTaskDetails';
import AuthPage from './components/AuthPage';
import { CrewsProvider } from './contexts/CrewsContext';
import MyminionsLogo from './components/MyminionsLogo';
import GlowingGradientWrapper from './components/GlowingGradientWrapper';
import { QueryClient, QueryClientProvider } from 'react-query';
import Home from './components/Home';
import FloatingMenu from './components/FloatingMenu';
import Tasks from './components/Tasks';
import ImageGeneration from './components/ImageGeneration';
import ExploreTeams from './components/ExploreTeams';
import PublicCrewDetails from './components/PublicCrewDetails';
const queryClient = new QueryClient();

function App() {
  const { user, logout } = useAuth();

  return (
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <CrewsProvider>
          <Router>
            <Flex flexDirection="column" minH="100vh" bg="gray.100">
              {user && (
                <GlowingGradientWrapper>
                  <Box py={4}>
                    <Container maxW="container.xl">
                      <Flex alignItems="center" justifyContent="space-between" width="100%">
                        <Box>
                          <Link to="/">
                            <MyminionsLogo />
                          </Link>
                        </Box>
                        <Button 
                          onClick={logout} 
                          colorScheme="purple" 
                          variant="ghost"
                          borderRadius="full"
                          px={4}
                          py={1}
                          fontSize="sm"
                          fontWeight="semibold"
                          color="purple.600"
                          _hover={{ bg: "purple.50" }}
                          _active={{ bg: "purple.100" }}
                          transition="all 0.2s"
                        >
                          Logout
                        </Button>
                      </Flex>
                    </Container>
                  </Box>
                </GlowingGradientWrapper>
              )}
              <Box flex="1">
                <Routes>
                  <Route path="/login" element={!user ? <Box h="100vh"><AuthPage /></Box> : <Navigate to="/" replace />} />
                  <Route path="/" element={
                    user ? <Home /> : <Navigate to="/login" replace />
                  } />
                  <Route path="/crews/:id" element={user ? <CrewDetails /> : <Navigate to="/login" replace />} />
                  <Route path="/crews/:id/tasks/:taskId" element={user ? <FullScreenTaskDetails /> : <Navigate to="/login" replace />} />
                  <Route path="/sharedtask/:id" element={<ShareableTaskDetails />} />
                  <Route
                    path="/tasks"
                    element={user ? <Tasks /> : <Navigate to="/login" />}
                  />
                  <Route
                    path="/image-generation"
                    element={user ? <ImageGeneration /> : <Navigate to="/login" />}
                  />
                  <Route path="/explore" element={user ? <ExploreTeams /> : <Navigate to="/login" />} />
                  <Route path="/public-crews/:id" element={<PublicCrewDetails />} />
                  <Route path="/image-generation/:taskId" element={user ? <ImageGeneration /> : <Navigate to="/login" />} />
                </Routes>
              </Box>
              {user && <FloatingMenu />}
            </Flex>
          </Router>
        </CrewsProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;