import axios from 'axios';
import { getAuth } from 'firebase/auth';
import API_BASE_URL from '../config';

export const expandText = async (taskId, text) => {
    const prompt = "Expand the following text to be more detailed. Return the expanded text only.";
    try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          throw new Error('User not authenticated');
        }
    
        const idToken = await user.getIdToken();
    
        const response = await axios.post(
          `${API_BASE_URL}/edit-task/${taskId}`,
          { text: text, prompt: prompt },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
    
        if (!response.data) {
          throw new Error('Failed to edit task with AI');
        }
    
        return response.data;
      } catch (error) {
        console.error('Error editing task result:', error);
        throw error;
      }
};

export const updateTask = async (taskId, { result, thumbnailUrl }) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User not authenticated');
    }

    const idToken = await user.getIdToken();

    const updateData = { result };
    if (thumbnailUrl !== undefined) {
      updateData.thumbnail_url = thumbnailUrl;
    }

    const response = await axios.put(
      `${API_BASE_URL}/tasks/${taskId}`,
      updateData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (!response.data) {
      throw new Error('Failed to update task');
    }

    return response.data;
  } catch (error) {
    console.error('Error updating task:', error);
    throw error;
  }
};