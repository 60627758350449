import React, { useState } from 'react';
import { Box, Image, Text, VStack, Skeleton, IconButton, Tooltip, HStack, useDisclosure } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';

function ImagePreviewCard({ prompt, imageUrl, isSelected }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDownload = (e) => {
    e.stopPropagation();
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'generated-image.png';
    link.click();
  };

  return (
    <Box 
      borderWidth={isSelected ? 2 : 1}
      borderColor={isSelected ? "purple.500" : "gray.200"}
      borderRadius="lg" 
      overflow="hidden" 
      boxShadow={isSelected ? "md" : "sm"}
      position="relative"
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
      width="100%"
      maxWidth="300px"
      bg="gray.800"
      transition="all 0.2s"
      _hover={{ boxShadow: "md" }}
    >
      <Skeleton isLoaded={imageLoaded} height="200px" width="100%">
        <Image 
          src={imageUrl} 
          alt={prompt} 
          objectFit="cover"
          width="100%" 
          height="200px"
          onLoad={() => setImageLoaded(true)}
        />
      </Skeleton>
      
      {isOpen && (
        <HStack 
          position="absolute" 
          top={2} 
          right={2} 
          spacing={2}
          bg="rgba(0, 0, 0, 0.6)"
          borderRadius="md"
          p={1}
        >
          <Tooltip label="Download Image">
            <IconButton
              icon={<DownloadIcon />}
              onClick={handleDownload}
              aria-label="Download Image"
              size="sm"
              colorScheme="whiteAlpha"
              variant="ghost"
            />
          </Tooltip>
        </HStack>
      )}
      
      <VStack p={4} align="start" bg="gray.700" height="auto">
        <Text fontSize="sm" color="gray.400">
          {prompt}
        </Text>
      </VStack>
    </Box>
  );
}

export default ImagePreviewCard;