import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box, VStack, Heading, Text, Button, Flex, useColorModeValue, SimpleGrid,
  Card, CardHeader, CardBody, CardFooter, Collapse, useDisclosure, IconButton,
  useToast, Spinner, Center
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronUpIcon, ChevronDownIcon, CopyIcon, CheckIcon, StarIcon } from '@chakra-ui/icons';
import MinionCard from './MinionCard';
import TaskResultCard from './TaskResultCard';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import API_BASE_URL from '../config';
import LoadingSpinner from './LoadingSpinner';

function PublicCrewDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();  // Changed from { crewId } to { id }
  const [crewData, setCrewData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [followers, setFollowers] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followersCount, setFollowersCount] = useState(0);
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const { user } = useAuth();
  const toast = useToast();
  const [isSaved, setIsSaved] = useState(false);
  const [localCloneCount, setLocalCloneCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      fetchCrewData();
    } else {
      setIsLoading(false);
      toast({
        title: "Error",
        description: "Invalid crew ID. Please check the URL and try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [id]);  // Changed from [crewId] to [id]

  const fetchCrewData = async () => {
    setIsLoading(true);
    try {
      let data;
      if (location.state && location.state.crew && location.state.featured_tasks) {
        data = location.state;
      } else {
        const response = await axios.get(`${API_BASE_URL}/public-crews/${id}`);  // Changed from crewId to id
        data = response.data;
      }
      setCrewData(data);
      setFollowersCount(data.followers.length);
      setIsFollowing(user && data.followers.includes(user.uid));
      setLocalCloneCount(data.crew.num_clones);
    } catch (error) {
      console.error('Error fetching crew data:', error);
      toast({
        title: "Error",
        description: "Failed to load crew details. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFollowToggle = async () => {
    if (!user) {
      toast({
        title: "Please log in",
        description: "You need to be logged in to follow this team.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const idToken = await user.getIdToken();
      const endpoint = isFollowing ? 'unfollow' : 'follow';
      await axios.post(`${API_BASE_URL}/crews/${crewData.crew.id}/${endpoint}`, {}, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setIsFollowing(!isFollowing);
      setFollowersCount(prevCount => isFollowing ? prevCount - 1 : prevCount + 1);
    } catch (error) {
      console.error('Error toggling follow:', error);
      toast({
        title: "Error",
        description: `Failed to ${isFollowing ? 'unfollow' : 'follow'} the team. Please try again.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSaveToMyTeams = async () => {
    if (!user) {
      toast({
        title: "Please log in",
        description: "You need to be logged in to copy this team.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const idToken = await user.getIdToken();
      await axios.post(`${API_BASE_URL}/public-crews/${crewData.crew.id}/copy`, {}, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setIsSaved(true);
      setLocalCloneCount(prevCount => prevCount + 1);
      toast({
        title: "Team copied",
        description: "This team has been copied to your library.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error copying team:', error);
      toast({
        title: "Error",
        description: "Failed to copy the team. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleTaskClick = (taskId) => {
    navigate(`/sharedtask/${taskId}`);
  };

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!id) {
    return <Box p={6}>Invalid team ID</Box>;
  }

  if (!crewData) {
    return <Box p={6}>Team not found</Box>;
  }

  return (
    <Box p={6}>
      <VStack align="stretch" spacing={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <IconButton
            icon={<ChevronLeftIcon boxSize={6} />}
            colorScheme="teal"
            variant="outline"
            onClick={() => navigate(-1)}
            aria-label="Back"
            isRound
            size="md"
            borderWidth={2}
          />
        </Flex>
        <Heading color="teal.600">{crewData.crew.name}</Heading>
        <Flex flexDirection={["column", "row"]} alignItems={["flex-start", "center"]} gap={2}>
          <Button
            onClick={handleFollowToggle}
            leftIcon={isFollowing ? <CheckIcon /> : <StarIcon />}
            colorScheme={isFollowing ? "green" : "blue"}
            variant="outline"
            borderRadius="full"
            h="36px"
          >
            {isFollowing ? "Following" : "Follow"} • {followersCount}
          </Button>
          <Button
            onClick={handleSaveToMyTeams}
            leftIcon={isSaved ? <CheckIcon /> : <CopyIcon />}
            colorScheme={isSaved ? "green" : "purple"}
            variant="outline"
            borderRadius="full"
            h="36px"
            isDisabled={isSaved}
          >
            {isSaved ? "Saved" : "Copy"} • {localCloneCount}
          </Button>
        </Flex>
      </VStack>
      <VStack spacing={6} align="stretch" mt={4}>
        <Card>
          <CardHeader pb={4}>
            <Flex alignItems="center" justifyContent="space-between">
              <Heading size="md" color="teal.500">
                <Text as="span" color="gray.500">This team has </Text>
                <Text
                  as="span"
                  color="teal.500"
                  cursor="pointer"
                  onClick={onToggle}
                  _hover={{ fontWeight: "extrabold" }}
                  transition="font-weight 0.1s"
                >
                  {crewData.crew.agents.length === 0 ? (
                    <Text as="span" color="orange.500">no minions</Text>
                  ) : crewData.crew.agents.length === 1 ? (
                    '1 minion'
                  ) : (
                    `${crewData.crew.agents.length} minions`
                  )}
                </Text>
              </Heading>
            </Flex>
          </CardHeader>
          <Collapse in={isOpen} animateOpacity>
            <CardBody pt={0}>
              <SimpleGrid columns={[1, null, 2]} spacing={4}>
                {crewData.crew.agents
                  .sort((a, b) => a.role.localeCompare(b.role))
                  .map((agent) => (
                    <MinionCard 
                      key={agent.id} 
                      agent={agent} 
                      isEditable={false}
                      isSelectable={false}
                    />
                  ))}
              </SimpleGrid>
            </CardBody>
          </Collapse>
          <CardFooter>
            <Button 
              onClick={onToggle} 
              variant="ghost" 
              width="100%" 
              justifyContent="center"
              leftIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            >
              {isOpen ? "Hide minions" : "See minions"}
            </Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <Heading size="md" color="teal.500">Featured Tasks</Heading>
          </CardHeader>
          <CardBody>
            <Flex justifyContent="center">
              <SimpleGrid columns={[1, null, 2, 3]} spacing={14}>
                {crewData.featured_tasks.map((task) => (
                  <TaskResultCard 
                    key={task.id} 
                    task={task} 
                    isSelected={false}
                    onSelect={() => handleTaskClick(task.id)}
                  />
                ))}
              </SimpleGrid>
            </Flex>
          </CardBody>
        </Card>
      </VStack>
    </Box>
  );
}

export default PublicCrewDetails;