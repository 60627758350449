import React from 'react';
import { Container, Flex, Box } from '@chakra-ui/react';
import CrewForm from './CrewForm';
import CrewList from './CrewList';

function Home() {
  return (
    <Container maxW="container.xl" mt={8} pb={16}>
      <Flex direction={["column", "column", "row"]} gap={8}>
        <Box flex={1}>
          <CrewForm />
        </Box>
        <Box width="1px" bg="gray.300" mx={4} display={["none", "none", "block"]} />
        <Box flex={1}>
          <CrewList />
        </Box>
      </Flex>
    </Container>
  );
}

export default Home;
