import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, VStack, Text, Flex, useColorModeValue, Spinner, Alert, AlertIcon, AlertTitle, AlertDescription, Select } from '@chakra-ui/react';
import MarkdownPreviewComponent from './MarkdownPreview';

const TypewriterText = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  
  useEffect(() => {
    let index = 0;
    setDisplayText('');
    
    const intervalId = setInterval(() => {
      if (index < text.length) {
        setDisplayText(text.slice(0, index + 1));
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 50); // Adjust speed here
    
    return () => clearInterval(intervalId);
  }, [text]);
  
  return <Text fontSize="md" fontStyle="italic" color="pink.500">{displayText}</Text>;
};

const WorkflowResultPanel = ({ 
  workflowResult, 
  stepResults, 
  selectedStepId, 
  onSave, 
  onClose, 
  isSaving, 
  isLoading, 
  error, 
  statusUpdates,
  nodeLabels,
  taskId // Add this line
}) => {
  const [selectedStep, setSelectedStep] = useState('final');
  const [currentOutput, setCurrentOutput] = useState('');
  const [markdownKey, setMarkdownKey] = useState(0);
  const markdownRef = useRef(null);

  const getStepOutput = useCallback((stepId) => {
    if (stepId === 'final') {
      return workflowResult;
    }
    const step = stepResults.find(step => step.id === stepId);
    return step ? step.output : '';
  }, [workflowResult, stepResults]);

  useEffect(() => {
    const output = getStepOutput(selectedStep);
    setCurrentOutput(output);
    setMarkdownKey(prevKey => prevKey + 1);
  }, [selectedStep, getStepOutput]);

  useEffect(() => {
    // Update currentOutput when workflowResult changes
    if (selectedStep === 'final') {
      setCurrentOutput(workflowResult);
      setMarkdownKey(prevKey => prevKey + 1);
    }
  }, [workflowResult, selectedStep]);

  const handleStepChange = useCallback((event) => {
    const newStep = event.target.value;
    setSelectedStep(newStep);
    if (markdownRef.current) {
      markdownRef.current.scrollTop = 0;
    }
  }, []);

  const cardBgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.500');

  const latestStatusUpdate = statusUpdates[statusUpdates.length - 1] || '';

  // Helper function to get the label for a step
  const getStepLabel = (stepId) => {
    if (stepId === 'final') {
      return 'Final Output';
    }
    return nodeLabels[stepId] || stepId;
  };

  return (
    <Box 
      bg={cardBgColor} 
      borderRadius="xl"
      boxShadow="xl"
      display="flex" 
      flexDirection="column" 
      height="90vh"
      overflow="hidden"
    >
      <VStack spacing={4} align="stretch" p={6} flex="1" overflowY="hidden">
        <Flex justifyContent="flex-start" alignItems="center" gap={4}>
          <Text fontSize="xl" fontWeight="bold">Workflow Step Result</Text>
          {!isLoading && !error && (
            <Select value={selectedStep} onChange={handleStepChange} width="auto" size="sm" borderRadius="md" borderColor="gray.200" fontWeight="semibold">
              <option value="final" style={{ color: "red.400", fontWeight: "semibold" }}>Final output</option>
              {stepResults.map((step) => (
                <option key={step.id} value={step.id} style={{ color: "gray.400", fontWeight: "semibold" }}>{getStepLabel(step.id)}</option>
              ))}
            </Select>
          )}
        </Flex>
        <Box flex="1" overflowY="auto" width="100%" ref={markdownRef}>
          {isLoading ? (
            <VStack spacing={4} align="center" justify="center" height="100%">
              <Spinner size="xl" color="pink.500" />
              <Box height="50px">
                <TypewriterText text={latestStatusUpdate} />
              </Box>
            </VStack>
          ) : error ? (
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              borderRadius="xl"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Oops! Something went wrong
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                {error}
              </AlertDescription>
            </Alert>
          ) : (
            <Box textAlign="left" width="100%">
              <MarkdownPreviewComponent
                key={markdownKey}
                initialContent={currentOutput}
                editable={false}
                taskId={taskId}
                onContentUpdate={() => {}}
              />
            </Box>
          )}
        </Box>
      </VStack>
      {!isLoading && !error && (
        <Flex justifyContent="flex-end" p={4} borderTop="1px" borderColor={borderColor}>
          <Text fontSize="sm" color="gray.500">Generated by your minions</Text>
        </Flex>
      )}
    </Box>
  );
};

export default WorkflowResultPanel;