import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config';
import {
  Box,
  VStack,
  Text,
  Heading,
  SimpleGrid,
  useColorModeValue,
  Spinner,
  Center,
  Button,
} from '@chakra-ui/react';
import PublicCrewCard from './PublicCrewCard';
import LoadingSpinner from './LoadingSpinner';
function ExploreTeams() {
  const [publicCrews, setPublicCrews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const pageSize = 9;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPublicCrews();
  }, []); // Run only once on mount

  const fetchPublicCrews = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/public/crews`, {
        params: { page: page, page_size: pageSize }
      });
      if (response.data.length < pageSize) {
        setHasMore(false);
      }
      setPublicCrews(prevCrews => {
        const existingIds = new Set(prevCrews.map(crew => crew.crew.id));
        const newCrews = response.data.filter(crew => !existingIds.has(crew.crew.id));
        const combinedCrews = [...prevCrews, ...newCrews];
        // Sort the combined crews by num_clones in descending order
        return combinedCrews.sort((a, b) => b.crew.num_clones - a.crew.num_clones);
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching public crews:', error);
      setLoading(false);
    }
  };

  const loadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    if (page > 0) {
      fetchPublicCrews();
    }
  }, [page]); // Fetch more crews when page changes

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Box maxWidth="90vw" margin="auto" mt={8} mb={8}>
      <VStack align="stretch" spacing={4} py={4}>
        <Heading as="h1" size="xl" color={textColor}>Explore Public Teams</Heading>
        <Text fontSize="lg" color="gray.600" mb={2}>
          Browse publicly available teams and save them to your library. You can customize teams to fit your needs once saved.
        </Text>
        <SimpleGrid columns={[1, null, 2]} spacing={8}>
          {publicCrews.map((crewData) => (
            <PublicCrewCard key={crewData.crew.id} crew={crewData.crew} followers={crewData.followers} featuredTasks={crewData.featured_tasks} />
          ))}
        </SimpleGrid>
        {hasMore && (
          <Button onClick={loadMore} colorScheme="teal" variant="outline" mt={4}>
            Load More
          </Button>
        )}
      </VStack>
    </Box>
  );
}

export default ExploreTeams;