import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Heading, Text, VStack, Button, useToast, FormControl, FormLabel, Textarea, SimpleGrid, Card, CardHeader, CardBody, CardFooter, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Badge, IconButton, Flex, Collapse, Menu, MenuButton, MenuList, MenuItem, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Switch, Input, InputGroup, InputRightElement, Tooltip, Spinner, Center } from '@chakra-ui/react';
import { AddIcon, ChevronLeftIcon, ChevronUpIcon, ChevronDownIcon, SettingsIcon, CopyIcon } from '@chakra-ui/icons';
import { FaProjectDiagram } from 'react-icons/fa';
import { TiFlowSwitch } from "react-icons/ti";
import axios from 'axios';
import API_BASE_URL from '../config';
import TaskDetailsModal from './TaskDetailsModal';
import MinionCard from './MinionCard';
import TaskCard from './TaskCard';
import AgentFormModal from './AgentFormModal';
import AgentLibrary from './AgentLibrary';
import FullScreenTaskDetails from './FullScreenTaskDetails';
import { getAuth } from 'firebase/auth';
import TaskForm from './TaskForm';
import { useAuth } from '../contexts/AuthContext'; // Add this import
import CrewSettingsDrawer from './CrewSettingsDrawer';
import InteractiveCanvas from './InteractiveCanvas';

function CrewDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [crew, setCrew] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const { isOpen: isTaskModalOpen, onOpen: onTaskModalOpen, onClose: onTaskModalClose } = useDisclosure();
  const { isOpen: isNewTaskModalOpen, onOpen: onNewTaskModalOpen, onClose: onNewTaskModalClose } = useDisclosure();
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: false });
  const toast = useToast();
  const [editingAgent, setEditingAgent] = useState(null);
  const { isOpen: isAgentModalOpen, onOpen: onAgentModalOpen, onClose: onAgentModalClose } = useDisclosure();
  const { isOpen: isAgentLibraryOpen, onOpen: onAgentLibraryOpen, onClose: onAgentLibraryClose } = useDisclosure();
  const [isLibraryOpen, setIsLibraryOpen] = useState(false);
  const [taskFilter, setTaskFilter] = useState('All');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { isOpen: isCanvasOpen, onOpen: onCanvasOpen, onClose: onCanvasClose } = useDisclosure();


  useEffect(() => {
    fetchCrew();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAuthToken = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('User not authenticated');
      // Handle the case where the user is not logged in
      return null;
    }

    return await user.getIdToken();
  };

  const fetchCrew = async () => {
    try {
      const idToken = await getAuthToken();
      if (!idToken) return;

      const response = await axios.get(`${API_BASE_URL}/crews/${id}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setCrew(response.data);
    } catch (error) {
      console.error('Error fetching team details:', error);
    }
  };

  const deleteCrew = async () => {
    try {
      const idToken = await getAuthToken();
      if (!idToken) return;

      await axios.delete(`${API_BASE_URL}/crews/${id}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      toast({
        title: 'Team deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/');
    } catch (error) {
      console.error('Error deleting team:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete team.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const executeTask = async (taskId) => {
    try {
      const idToken = await getAuthToken();
      if (!idToken) return;

      await axios.post(`${API_BASE_URL}/crews/${id}/execute/${taskId}`, {}, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      toast({
        title: 'Task execution started.',
        description: 'The task is now executing.',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      fetchCrew();
      pollTaskStatus(taskId);
    } catch (error) {
      console.error('Error starting task execution:', error);
      toast({
        title: 'Error',
        description: 'Failed to start task execution.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const pollTaskStatus = useCallback((taskId) => {
    const interval = setInterval(async () => {
      try {
        const idToken = await getAuthToken();
        if (!idToken) {
          clearInterval(interval);
          return;
        }

        const response = await axios.get(`${API_BASE_URL}/tasks/${taskId}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        const task = response.data;
        if (task.status === 2) {
          clearInterval(interval);
          toast({
            title: 'Task completed',
            description: 'The task has finished executing.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          fetchCrew();
        }
      } catch (error) {
        console.error('Error polling task status:', error);
        clearInterval(interval);
      }
    }, 20000);

    return () => clearInterval(interval);
  }, [toast, fetchCrew]);


  const toggleWebSearch = async (agentId, enabled) => {
    try {
      const idToken = await getAuthToken();
      if (!idToken) return;

      // Ensure we're sending the correct boolean value
      const updatedEnabled = enabled === true;
      console.log(updatedEnabled);
      await axios.put(`${API_BASE_URL}/crews/${id}/agents/${agentId}`, 
        { web_search_enabled: updatedEnabled },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      setCrew(prevCrew => ({
        ...prevCrew,
        agents: prevCrew.agents.map(agent => 
          agent.id === agentId ? { ...agent, web_search_enabled: updatedEnabled } : agent
        )
      }));

      toast({
        title: `Web search ${updatedEnabled ? 'enabled' : 'disabled'}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error toggling web search:', error);
      toast({
        title: 'Error',
        description: 'Failed to toggle web search.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleTaskClick = (task) => {
    if (task.status === 2) { // Completed task
      navigate(`/crews/${id}/tasks/${task.id}`, { 
        state: { crew, task },
        replace: true // This will replace the current entry in the history stack
      });
      window.scrollTo(0, 0); // Scroll to the top of the page
    } else {
      openTaskModal(task.id);
    }
  };

  const openTaskModal = async (taskId) => {
    if (!taskId) {
      console.error('Task ID is undefined');
      return;
    }
    try {
      const idToken = await getAuthToken();
      if (!idToken) return;

      const response = await axios.get(`${API_BASE_URL}/tasks/${taskId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setSelectedTask(response.data);
      onTaskModalOpen();
    } catch (error) {
      console.error('Error fetching task details:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch task details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditAgent = (agent) => {
    setEditingAgent(agent);
    onAgentModalOpen();
  };

  const handleAddCustomAgent = () => {
    setEditingAgent(null);
    onAgentModalOpen();
  };

  const handleSaveAgent = async (updatedAgent) => {
    try {
      const idToken = await getAuthToken();
      if (!idToken) return;

      if (editingAgent) {
        await axios.put(`${API_BASE_URL}/crews/${id}/agents/${editingAgent.id}`, {
          ...updatedAgent,
          id: editingAgent.id
        }, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
      } else {
        await axios.post(`${API_BASE_URL}/crews/${id}/agents`, updatedAgent, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
      }
      fetchCrew();
      toast({
        title: editingAgent ? 'Minion updated' : 'Minion added',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onAgentModalClose();
    } catch (error) {
      console.error('Error saving agent:', error);
      toast({
        title: 'Error',
        description: 'Failed to save minion',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSelectPresetAgent = async (agent) => {
    try {
      const idToken = await getAuthToken();
      if (!idToken) return;

      await axios.post(`${API_BASE_URL}/crews/${id}/agents`, agent, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      fetchCrew();
      onAgentLibraryClose();
      onAgentModalClose(); // Dismiss the AgentFormModal
      toast({
        title: 'Agent added',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding preset agent:', error);
      toast({
        title: 'Error',
        description: 'Failed to add preset agent',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteAgent = async (agentId) => {
    try {
      const idToken = await getAuthToken();
      if (!idToken) return;

      await axios.delete(`${API_BASE_URL}/crews/${id}/agents/${agentId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      fetchCrew();
      toast({
        title: 'Minion deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting agent:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete minion',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateTask = (updatedTask) => {
    if (crew) {
      const updatedTasks = crew.tasks.map(task => 
        task.id === updatedTask.id ? updatedTask : task
      );
      setCrew(prevCrew => ({
        ...prevCrew,
        tasks: updatedTasks
      }));
    }
  };

  const deleteTask = async (taskId) => {
    try {
      const idToken = await getAuthToken();
      if (!idToken) return;

      await axios.delete(`${API_BASE_URL}/tasks/${taskId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      
      setCrew(prevCrew => ({
        ...prevCrew,
        tasks: prevCrew.tasks.filter(task => task.id !== taskId)
      }));
      
      toast({
        title: 'Task deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting task:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete task',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const TaskList = ({ tasks }) => {
    const sortedTasks = [...tasks].sort((a, b) => {
      if (a.status !== b.status) {
        return a.status - b.status;
      }
      return new Date(b.updated_at) - new Date(a.updated_at);
    });

    return (
      <VStack spacing={4} align="stretch">
        {sortedTasks.map((task) => (
          <TaskCard 
            key={task.id} 
            task={task} 
            onClick={() => handleTaskClick(task)} 
            executeTask={executeTask}
            deleteTask={deleteTask}
          />
        ))}
      </VStack>
    );
  };

  const taskId = location.pathname.match(/\/tasks\/(\w+)/)?.[1];
  if (taskId) {
    const task = crew?.tasks.find(t => t.id === taskId);
    if (task) {
      return (
        <FullScreenTaskDetails
          task={task}
          crew={crew}
          updateTask={updateTask}
          onClose={() => navigate(`/crews/${id}`)}
        />
      );
    }
  }

  if (!crew) return (
    <Center height="100vh">
      <Spinner
        thickness="3px"
        speed="0.65s"
        emptyColor="gray.200"
        color="purple.300"
        size="xl"
      />
    </Center>
  );

  const filteredTasks = {
    'All': crew.tasks,
    'Not Started': crew.tasks.filter(task => task.status === 0),
    'In Progress': crew.tasks.filter(task => task.status === 1),
    'Completed': crew.tasks.filter(task => task.status === 2)
  };

  const handleSettingsClick = () => {
    setIsSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setIsSettingsOpen(false);
  };

  const addTask = async (taskData) => {
    try {
      setCrew(prevCrew => ({
        ...prevCrew,
        tasks: [...prevCrew.tasks, taskData]
      }));

      // Refetch the crew data to ensure everything is up to date
      fetchCrew();

      onNewTaskModalClose();
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  const togglePublicStatus = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated');
        return;
      }

      const idToken = await user.getIdToken();

      const response = await axios.post(`${API_BASE_URL}/crews/${id}/toggle-public`, {}, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      
      setCrew(prevCrew => ({
        ...prevCrew,
        is_public: response.data.is_public
      }));
      
      toast({
        title: response.data.is_public ? "Your team is now public" : "Your team is now private",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error toggling team public status:', error);
      toast({
        title: "Error",
        description: "Failed to update team share status",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateCrewDetails = async (updatedDetails) => {
    try {
      const idToken = await getAuthToken();
      if (!idToken) return;

      const response = await axios.put(`${API_BASE_URL}/crews/${id}`, updatedDetails, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      
      setCrew(prevCrew => ({
        ...prevCrew,
        ...response.data
      }));
      
      toast({
        title: "Team details updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating crew details:', error);
      toast({
        title: "Error",
        description: "Failed to update team details",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={6}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex alignItems="center">
            <IconButton
              icon={<ChevronLeftIcon boxSize={6} />}
              colorScheme="teal"
              variant="outline"
              mr={4}
              onClick={() => navigate('/')}
              aria-label="Back"
              isRound
              size="md"
              borderWidth={2}
            />
            <Heading color="teal.600">{crew.name}</Heading>
          </Flex>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Settings"
              icon={<SettingsIcon />}
              variant="ghost"
              color="gray.400"
              size="lg"
              borderRadius="full"
            />
            <MenuList>
              <MenuItem onClick={handleSettingsClick}>Team Settings</MenuItem>
              <MenuItem
                color="red.500"
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this team? This action cannot be undone.')) {
                    deleteCrew();
                  }
                }}
              >
                Delete Team
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <VStack spacing={6} align="stretch" mt={4}>
        <Card>
          <CardHeader pb={4}>
            <Flex alignItems="center" justifyContent="space-between">
              <Heading size="md" color="teal.500">
                <Text as="span" color="gray.500">This team has </Text>
                <Text
                  as="span"
                  color="teal.500"
                  cursor="pointer"
                  onClick={onToggle}
                  _hover={{ fontWeight: "extrabold" }}
                  transition="font-weight 0.1s"
                >
                  {crew.agents.length === 0 ? (
                    <Text as="span" color="orange.500">no minions</Text>
                  ) : crew.agents.length === 1 ? (
                    '1 minion'
                  ) : (
                    `${crew.agents.length} minions`
                  )}
                </Text>
              </Heading>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Add agent"
                  icon={<AddIcon />}
                  variant="outline"
                  colorScheme="teal"
                  size="sm"
                  opacity={0.7}
                  _hover={{ opacity: 1 }}
                  borderRadius="full"
                />
                <MenuList>
                  <MenuItem onClick={handleAddCustomAgent}>Create custom minion</MenuItem>
                  <MenuItem onClick={() => setIsLibraryOpen(true)}>Choose from library</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </CardHeader>
          <Collapse in={isOpen} animateOpacity>
            <CardBody pt={0}>
              <SimpleGrid columns={[1, null, 2]} spacing={4}>
                {crew.agents
                  .sort((a, b) => a.role.localeCompare(b.role))
                  .map((agent) => (
                    <MinionCard 
                      key={agent.id} 
                      agent={agent} 
                      toggleWebSearch={toggleWebSearch} 
                      onEdit={handleEditAgent}
                      onDelete={handleDeleteAgent}
                      isEditable={true}
                      isSelectable={true}
                    />
                  ))}
              </SimpleGrid>
            </CardBody>
          </Collapse>
          <CardFooter>
            <Button 
              onClick={onToggle} 
              variant="ghost" 
              width="100%" 
              justifyContent="center"
              leftIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            >
              {isOpen ? "Hide minions" : "See my minions"}
            </Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Heading size="md" color="teal.500" mr={2}>Tasks</Heading>
              <Flex>
                {filteredTasks['Not Started'].length > 0 && (
                  <Button
                    colorScheme="teal"
                    size="sm"
                    onClick={() => filteredTasks['Not Started'].forEach(task => executeTask(task.id))}
                    mr={2}
                    borderRadius="full"
                  >
                    Run pending tasks
                  </Button>
                )}
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="outline" size="sm" borderRadius="full">
                    {taskFilter} ({filteredTasks[taskFilter].length})
                  </MenuButton>
                  <MenuList>
                    {Object.keys(filteredTasks).map(filter => (
                      <MenuItem key={filter} onClick={() => setTaskFilter(filter)}>
                        {filter} ({filteredTasks[filter].length})
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
                <IconButton
                  icon={<AddIcon />}
                  colorScheme="teal"
                  aria-label="Add new task"
                  size="sm"
                  onClick={onNewTaskModalOpen}
                  isRound
                  opacity={0.7}
                  _hover={{ opacity: 1 }}
                  ml={2}
                />
                <Tooltip label="Interactive Mode" hasArrow>
                  <IconButton
                    icon={<TiFlowSwitch />}
                    aria-label="Interactive Mode"
                    size="sm"
                    onClick={onCanvasOpen}
                    isRound
                    ml={2}
                    bg="purple.100"
                    color="purple.500"
                    _hover={{ bg: "purple.200" }}
                  />
                </Tooltip>
              </Flex>
            </Box>
          </CardHeader>
          <CardBody>
            <TaskList tasks={filteredTasks[taskFilter]} />
          </CardBody>
        </Card>
      </VStack>

      <TaskDetailsModal
        isOpen={isTaskModalOpen}
        onClose={() => {
          onTaskModalClose();
          setSelectedTask(null);
        }}
        task={selectedTask}
        executeTask={executeTask}
        crew={crew}
        updateTask={updateTask}
      />

      <TaskForm
        isOpen={isNewTaskModalOpen}
        onClose={onNewTaskModalClose}
        onSubmit={addTask}
        crewId={id}
      />

      <AgentFormModal
        isOpen={isAgentModalOpen}
        onClose={onAgentModalClose}
        onSave={handleSaveAgent}
        initialAgent={editingAgent}
      />

      <Modal isOpen={isLibraryOpen} onClose={() => setIsLibraryOpen(false)} size="6xl">
        <ModalOverlay />
        <ModalContent borderRadius="3xl" overflow="hidden">
          <ModalCloseButton />
          <ModalBody pt={10} pb={20}>
            <AgentLibrary
              onSelectAgent={handleSelectPresetAgent}
              selectedAgentIds={crew.agents.map(agent => agent.id)}
              displayGrid={true}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <CrewSettingsDrawer
        isOpen={isSettingsOpen}
        onClose={handleSettingsClose}
        crew={crew}
        togglePublicStatus={togglePublicStatus}
        updateCrewDetails={updateCrewDetails}
      />

      <Modal isOpen={isCanvasOpen} onClose={onCanvasClose} size="full">
        <ModalOverlay />
        <ModalContent bg='pink.50'>
          <ModalHeader>Interactive Canvas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InteractiveCanvas crew={crew} crewId={id} />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCanvasClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export function getStatusBadge(status) {
  switch (status) {
    case 0:
      return <Badge colorScheme="gray" borderRadius="full" px={2} py={0.5}>Not Started</Badge>;
    case 1:
      return <Badge colorScheme="yellow" borderRadius="full" px={2} py={0.5}>In Progress</Badge>;
    case 2:
      return <Badge colorScheme="green" borderRadius="full" px={2} py={0.5}>Completed</Badge>;
    default:
      return <Badge borderRadius="full" px={2} py={0.5}>Unknown</Badge>;
  }
}

export default CrewDetails;