import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL, { WS_BASE_URL } from '../config';
import {
  Box,
  VStack,
  Text,
  Heading,
  Flex,
  useColorModeValue,
  Divider,
  Spinner,
  Center,
  SimpleGrid,
  IconButton,
  Tooltip,
  useToast,
  Spacer,
  Icon,
  Collapse,
  Button,
  Link,
  Code,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Skeleton,
  Box as ChakraBox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
} from '@chakra-ui/react';
import { MdChat } from "react-icons/md";
import { ChevronLeftIcon, EditIcon, ChevronDownIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import MDEditor from '@uiw/react-md-editor';
import '@uiw/react-md-editor/markdown-editor.css';
import '@uiw/react-markdown-preview/markdown.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism as lightTheme, atomDark as darkTheme } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { MdContentCopy, MdKeyboardArrowUp, MdKeyboardArrowDown, MdLink, MdLinkOff, MdCheck, MdUpdate } from 'react-icons/md';
import MinionCard from './MinionCard';
import { getAuth } from 'firebase/auth';
import FormattedMarkdown from './FormattedMarkdown';
import MarkdownPreviewComponent from './MarkdownPreview';
import { formatDate } from '../utils/dateUtils';

import { getCodeString } from 'rehype-rewrite';
import mermaid from "mermaid";
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { updateTask } from '../utils/api';  // Updated import
import EditableImage from './EditableImage';

const randomid = () => parseInt(String(Math.random() * 1e15), 10).toString(36);
const CodeRenderer = ({ inline, children = [], className, ...props }) => {
  const demoid = useRef(`dome${randomid()}`);
  const [container, setContainer] = useState(null);
  const isMermaid =
    className && /^language-mermaid/.test(className.toLocaleLowerCase());
  const code = children
    ? getCodeString(props.node.children)
    : children[0] || "";

  useEffect(() => {
    if (container && isMermaid && demoid.current && code) {
      mermaid
        .render(demoid.current, code)
        .then(({ svg, bindFunctions }) => {
          container.innerHTML = svg;
          if (bindFunctions) {
            bindFunctions(container);
          }
        })
        .catch((error) => {
          console.log("error:", error);
        });
    }
  }, [container, isMermaid, code, demoid]);

  const refElement = useCallback((node) => {
    if (node !== null) {
      setContainer(node);
    }
  }, []);

  if (isMermaid) {
    return (
      <Fragment>
        <code id={demoid.current} style={{ display: "none" }} />
        <code className={className} ref={refElement} data-name="mermaid" />
      </Fragment>
    );
  }
  return <code className={className}>{children}</code>;
};

function FullScreenTaskDetails() {
  const [task, setTask] = useState(null);
  const [crew, setCrew] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMinionsExpanded, setIsMinionsExpanded] = useState(true);
  const [isShared, setIsShared] = useState(false);
  const [shareableLink, setShareableLink] = useState('');
  const [linkCopied, setLinkCopied] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedResult, setEditedResult] = useState('');
  const { id: crewId, taskId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const sectionBgColor = useColorModeValue('white', 'gray.700');
  const codeTheme = useColorModeValue(lightTheme, darkTheme);

  const [markdownKey, setMarkdownKey] = useState(0);

  const [chatInput, setChatInput] = useState('');
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [tempResult, setTempResult] = useState(null);
  const [showAcceptRejectButtons, setShowAcceptRejectButtons] = useState(false);
  const [isLoadingNewResult, setIsLoadingNewResult] = useState(false);

  const [selectedStep, setSelectedStep] = useState('final');
  const [currentOutput, setCurrentOutput] = useState('');

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${WS_BASE_URL}/ws/chat/${taskId}`,
    {
      shouldReconnect: (closeEvent) => true,
    }
  );

  useEffect(() => {
    if (lastMessage !== null) {
      const messageData = JSON.parse(lastMessage.data);
      if (messageData.result) {
        setIsLoadingNewResult(true);
        setTimeout(() => {
          setTempResult(messageData.result);
          setMarkdownKey(prevKey => prevKey + 1);
          setShowAcceptRejectButtons(true);
          setIsWaitingForResponse(false);
          setIsLoadingNewResult(false);
        }, 300);
      } else if (messageData.error) {
        toast({
          title: "Error",
          description: messageData.error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsWaitingForResponse(false);
      }
    }
  }, [lastMessage]);

  const handleSendMessage = () => {
    if (chatInput.trim()) {
      sendMessage(JSON.stringify({ prompt: chatInput }));
      setChatInput('');
      setIsWaitingForResponse(true);
      setShowAcceptRejectButtons(false);
    }
  };

  const handleAcceptChange = async () => {
    try {
      const updatedTask = await updateTask(taskId, { result: tempResult }); // Updated function call
      updateTaskState(updatedTask);
      setShowAcceptRejectButtons(false);
      toast({
        title: "Result updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Failed to update task:', error);
      toast({
        title: "Error",
        description: "Failed to update task",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRejectChange = () => {
    setTempResult(undefined);
    setShowAcceptRejectButtons(false);
    setMarkdownKey(prevKey => prevKey + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          console.error('User not authenticated');
          setLoading(false);
          return;
        }

        const idToken = await user.getIdToken();

        const [taskResponse, crewResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/tasks/${taskId}`, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }),
          axios.get(`${API_BASE_URL}/crews/${crewId}`, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
        ]);
        setTask(taskResponse.data);
        setEditedResult(taskResponse.data.result);
        setCrew(crewResponse.data);
        setIsShared(taskResponse.data.is_shared || false);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [crewId, taskId]);

  const updateTaskState = (updatedTask) => {
    setTask(updatedTask);
    setEditedResult(updatedTask.result);
    // If thumbnailUrl is part of the updatedTask, you can handle it here
  };


  const getShareableLink = () => {
    return `${window.location.origin}/sharedtask/${task.id}`;
  };

  const copyShareableLink = () => {
    const link = getShareableLink();
    navigator.clipboard.writeText(link);
    setLinkCopied(true);
    toast({
      title: "Link copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    setTimeout(() => setLinkCopied(false), 2000);
  };

  const copyResultToClipboard = () => {
    navigator.clipboard.writeText(task.result);
    toast({
      title: "Result copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const toggleShareTask = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated');
        return;
      }

      const idToken = await user.getIdToken();

      const response = await axios.put(`${API_BASE_URL}/tasks/${task.id}/toggleshare`, {}, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      const updatedTask = { ...task, is_shared: response.data.is_shared };
      setIsShared(response.data.is_shared);
      updateTaskState(updatedTask);
      
      const shareableLink = `${window.location.origin}/sharedtask/${task.id}`;
      
      if (response.data.is_shared) {
        setShareableLink(shareableLink);
        navigator.clipboard.writeText(shareableLink);
        toast({
          title: "Task shared",
          description: "Your task is now public and a shareable link has been copied to your clipboard",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        setShareableLink('');
        toast({
          title: "Task unshared",
          description: "The task is no longer publicly accessible",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error toggling share status:', error);
      toast({
        title: "Error",
        description: "Failed to update share status",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleGoBack = () => {
    navigate(`/crews/${crewId}`, { replace: true });
  };

  const handleEditClick = () => {
    setEditedResult(task.result);
    setIsEditing(true);
  };

  const handleSaveEdit = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated');
        return;
      }

      const idToken = await user.getIdToken();

      const response = await axios.put(
        `${API_BASE_URL}/tasks/${task.id}`,
        { result: editedResult },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      const updatedTask = { ...task, result: response.data.result, updated_at: response.data.updated_at };
      updateTaskState(updatedTask);
      setIsEditing(false);
      setMarkdownKey(prevKey => prevKey + 1);
      toast({
        title: "Result updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating task result:', error);
      toast({
        title: "Error",
        description: "Failed to update task result",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleGenerateWithAI = () => {
    navigate(`/image-generation/${taskId}`);
  };

  const handleUploadImage = () => {
    // TODO: Implement image upload functionality
    console.log("Upload image functionality to be implemented");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (task) {
      const output = getStepOutput(selectedStep);
      setCurrentOutput(output);
      setMarkdownKey(prevKey => prevKey + 1);
    }
  }, [selectedStep, task]);

  const getStepOutput = useCallback((stepId) => {
    if (!task) return '';
    if (stepId === 'final') {
      return task.result;
    }
    const step = task.steps?.find(step => step.id === stepId);
    return step ? step.output : '';
  }, [task]);

  const handleStepChange = useCallback((event) => {
    const newStep = event.target.value;
    setSelectedStep(newStep);
  }, []);

  // Helper function to get the label for a step
  const getStepLabel = (stepId) => {
    if (stepId === 'final') {
      return 'Final Output';
    }
    const step = task?.steps.find(s => s.id === stepId);
    return step ? step.name : stepId;
  };

  const handleImageSelect = async (imageUrl) => {
    try {
      const now = new Date().toISOString();
      const updatedTask = await updateTask(taskId, { 
        thumbnailUrl: imageUrl,
        result: task.result // Include the current result to avoid overwriting it
      });
      
      setTask(prevTask => ({
        ...prevTask,
        thumbnail_url: imageUrl,
        updated_at: now
      }));
      
      toast({
        title: "Image updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Failed to update task:', error);
      toast({
        title: "Error",
        description: "Failed to update task image",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (!task || !crew) {
    return (
      <Box maxWidth="90vw" margin="auto" mt={8} mb={8}>
        <Text>Task or crew not found.</Text>
      </Box>
    );
  }

  return (
    <Box maxWidth="90vw" margin="auto" mt={8} mb={8}>
      <VStack align="stretch" spacing={6} py={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <IconButton
              icon={<ChevronLeftIcon boxSize={6} />}
              colorScheme="teal"
              variant="outline"
              mr={4}
              onClick={handleGoBack}
              aria-label="Back to Crew Details"
              isRound
              size="md"
              borderWidth={2}
            />
            <Heading as="h2" size="lg" color={textColor}>Task Details</Heading>
          </Flex>
        </Flex>

        {task.result && (
          <Box
            borderWidth="1px"
            borderRadius="lg"
            borderColor={borderColor}
            p={6}
            boxShadow="sm"
            transition="all 0.2s"
            _hover={{ boxShadow: 'md' }}
            bg={sectionBgColor}
          >
            <Flex 
              justifyContent="space-between" 
              alignItems="flex-start" 
              mb={4}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Heading as="h2" size="lg" color={textColor} mb={{ base: 2, md: 0 }}>Result</Heading>
              <Flex alignItems="center" gap={2} flexWrap="wrap">
                {isShared && (
                  <Flex alignItems="center" bg="purple.100" p={2} pl={3} borderRadius="full" mr={2}>
                    <Text fontSize="sm" fontWeight="medium" color="purple.700" mr={2}>
                      Public
                    </Text>
                    <Tooltip label={linkCopied ? "Copied!" : "Copy link"} hasArrow>
                      <IconButton
                        icon={linkCopied ? <MdCheck /> : <MdContentCopy />}
                        onClick={copyShareableLink}
                        aria-label="Copy shareable link"
                        size="sm"
                        colorScheme="purple"
                        variant="solid"
                        borderRadius="full"
                        fontWeight="bold"
                        _hover={{ bg: "purple.600" }}
                        _active={{ bg: "purple.700" }}
                      />
                    </Tooltip>
                  </Flex>
                )}

                <Tooltip label={isShared ? "Unshare task" : "Share task"} hasArrow>
                  <IconButton
                    icon={isShared ? <MdLinkOff /> : <MdLink />}
                    onClick={toggleShareTask}
                    aria-label={isShared ? "Unshare task" : "Share task"}
                    borderRadius="full"
                    bg={isShared ? "red.50" : "transparent"}
                    color={isShared ? "red.700" : "inherit"}
                    _hover={{ bg: isShared ? "red.100" : "blue.50", color: isShared ? "red.800" : "blue.700" }}
                  />
                </Tooltip>
                <Tooltip label="Edit result" hasArrow>
                  <Button
                    leftIcon={<EditIcon />}
                    onClick={handleEditClick}
                    borderRadius="full"
                    bg="green.50"
                    color="green.700"
                    _hover={{ bg: "green.100" }}
                    fontWeight="bold"
                  >
                    Edit
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
            <Flex align="center" color="gray.500" fontSize="sm" mt={2} mb={4} flexWrap="wrap">
              <Icon as={MdUpdate} mr={1} />
              <Text>Last updated {formatDate(new Date(task.updated_at)).replace(/^(.)/, c => c.toLowerCase())}</Text>
            </Flex>

            {task.steps && task.steps.length > 0 && (
              <Box mt={4} mb={6}>
                <Heading as="h4" size="md" color={textColor} mb={2}>Select step result</Heading>
                <Select 
                  value={selectedStep} 
                  onChange={handleStepChange} 
                  width="100%" 
                  maxWidth="300px"
                  size="md" 
                  borderRadius="md" 
                  borderColor="gray.200" 
                  fontWeight="semibold"
                >
                  <option value="final" style={{ color: "red.400", fontWeight: "semibold" }}>Final output</option>
                  {task.steps.map((step) => (
                    <option key={step.id} value={step.id} style={{ color: "gray.400", fontWeight: "semibold" }}>
                      {getStepLabel(step.id)}
                    </option>
                  ))}
                </Select>
              </Box>
            )}

            {task.thumbnail_url && (
              <EditableImage
                src={task.thumbnail_url}
                alt="Task thumbnail"
                onUploadImage={handleUploadImage}
                onImageSelect={handleImageSelect}
              />
            )}

            <Box borderRadius="md">
              <Skeleton isLoaded={!isLoadingNewResult}>
                <MarkdownPreviewComponent 
                  key={markdownKey} 
                  initialContent={tempResult || currentOutput} 
                  taskId={task.id} 
                  onContentUpdate={updateTaskState} 
                  editable={true} 
                />
              </Skeleton>
            </Box>
          </Box>
        )}

        {/* New chat input section */}
        <Box
          width="100%"
          maxWidth="600px"
          mx="auto"
          mt={2}
          mb={6}
        >
          {showAcceptRejectButtons && (
            <Flex 
              justifyContent="center" 
              mb={8} 
              flexDirection={{ base: "column", sm: "row" }}
              alignItems="center"
              width="100%"
            >
              <Button 
                onClick={handleAcceptChange} 
                colorScheme="teal" 
                mb={{ base: 2, sm: 0 }}
                mr={{ base: 0, sm: 2 }} 
                borderRadius="full"
                width={{ base: "100%", sm: "auto" }}
                leftIcon={<CheckIcon />}
                _hover={{ transform: 'scale(1.05)' }}
                transition="all 0.2s"
              >
                Accept
              </Button>
              <Button 
                onClick={handleRejectChange} 
                colorScheme="pink" 
                ml={{ base: 0, sm: 2 }}
                borderRadius="full"
                width={{ base: "100%", sm: "auto" }}
                leftIcon={<CloseIcon />}
                _hover={{ transform: 'scale(1.05)' }}
                transition="all 0.2s"
              >
                Reject
              </Button>
            </Flex>
          )}
          <Flex
            align="center"
            bg="white"
            borderRadius="full"
            boxShadow="lg"
            p={2}
          >
            <Input
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              placeholder={`Chat with your minions...`}
              border="none"
              _focus={{ boxShadow: 'none' }}
              flex={1}
              mr={2}
            />
            <Button
              onClick={handleSendMessage}
              colorScheme="purple"
              isDisabled={isWaitingForResponse}
              borderRadius="full"
              px={6}
            >
              {isWaitingForResponse ? (
                <Spinner size="sm" />
              ) : (
                <Icon as={MdChat} />
              )}
            </Button>
          </Flex>
        </Box>

        <Divider />

        <Heading as="h4" size="md" color='gray.500'>More about this task</Heading>
        
        <Box
          borderWidth="1px"
          borderRadius="lg"
          borderColor={borderColor}
          p={6}
          boxShadow="sm"
          transition="all 0.2s"
          _hover={{ boxShadow: 'md' }}
          bg={bgColor}
        >
          <VStack align="stretch" spacing={4}>
            <Box>
              <Heading as="h4" size="md" color='gray.500' mb={2}>Task description</Heading>
              <Text color='gray.500' fontSize="md">{task.description}</Text>
            </Box>
            <Box>
              <Heading as="h4" size="md" color='gray.500' mb={2}>Expected output</Heading>
              <Text color='gray.500' fontSize="md">{task.expected_output}</Text>
            </Box>
          </VStack>
        </Box>

        {crew && crew.agents && crew.agents.length > 0 && (
          <Box
            borderWidth="1px"
            borderRadius="lg"
            borderColor={borderColor}
            p={6}
            boxShadow="sm"
            transition="all 0.2s"
            _hover={{ boxShadow: 'md' }}
            bg={bgColor}
          >
            <Flex alignItems="center" mb={4} cursor="pointer" onClick={() => setIsMinionsExpanded(!isMinionsExpanded)}>
              <Heading as="h4" size="md" color='gray.500'>
                <Text as="span" color="purple.500">{crew.agents.length} minion{crew.agents.length !== 1 && 's'}</Text> worked on this task
              </Heading>
              <Spacer />
              <Icon 
                as={isMinionsExpanded ? MdKeyboardArrowUp : MdKeyboardArrowDown} 
                _hover={{ fill: "purple.100" }}
              />
            </Flex>
            <Collapse in={isMinionsExpanded}>
              <SimpleGrid columns={[1, 2, 3]} spacing={6}>
                {crew.agents.map((agent) => (
                  <MinionCard
                    key={agent.id}
                    agent={agent}
                    onEdit={() => {}}
                    toggleWebSearch={() => {}}
                    onDelete={() => {}}
                    isEditable={false}
                    isSelectable={false}
                  />
                ))}
              </SimpleGrid>
            </Collapse>
          </Box>
        )}
      </VStack>

      <Modal isOpen={isEditing} onClose={() => setIsEditing(false)} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="gray.700">Edit Task Result</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <MDEditor
              value={editedResult}
              onChange={setEditedResult}
              preview="live"
              height="80vh"
              highlightEnable={true}
              hideToolbar={false}
              enableScroll={true}
              previewOptions={{
                components: {
                  code: CodeRenderer,
                  ul: ({ children, ...props }) => (
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginBottom: '10px' }} {...props}>
                      {children}
                    </ul>
                  ),
                  ol: ({ children, ...props }) => (
                    <ol style={{ listStyleType: 'decimal', paddingLeft: '20px', marginBottom: '10px' }} {...props}>
                      {children}
                    </ol>
                  ),
                  li: ({ children, ...props }) => (
                    <li style={{ marginBottom: '5px' }} {...props}>
                      {children}
                    </li>
                  ),
                },
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button bg="purple.500" colorScheme="purple" mr={3} onClick={handleSaveEdit} borderRadius="full">
              Save
            </Button>
            <Button variant="ghost" onClick={() => setIsEditing(false)} borderRadius="full">Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default FullScreenTaskDetails;