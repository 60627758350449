import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Text
} from '@chakra-ui/react';
import TaskMentionSystem from './TaskMentionSystem';

function NodeModal({ isOpen, onClose, onSave, initialData, nodeType, tasks }) {
  const [label, setLabel] = useState(initialData.label || '');
  const [description, setDescription] = useState(initialData.description || '');
  const [expectedOutput, setExpectedOutput] = useState(initialData.expectedOutput || '');
  const [context, setContext] = useState(initialData.context || []);

  useEffect(() => {
    setLabel(initialData.label || '');
    setDescription(initialData.description || '');
    setExpectedOutput(initialData.expectedOutput || '');
    setContext(initialData.context || []);
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ label, description, expectedOutput, context });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent borderRadius="xl">
        <ModalHeader bg="purple.100" color="purple.700" borderTopRadius="xl" py={4}>
          Edit Step Details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl>
              <FormLabel fontSize="md" fontWeight="semibold" color="#3F536A">Name this step</FormLabel>
                <Textarea
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                  placeholder="Enter the name of the task"
                  focusBorderColor="purple.100"
                  borderColor="gray.200"
                  _hover={{ borderColor: "gray.300" }}
                  minHeight="40px"
                  maxHeight="80px"
                  resize="vertical"
                  rows={1}
                  overflowY="auto"
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize="md" fontWeight="semibold" color="#3F536A">Describe what you'd like to do</FormLabel>
                <Text mt={-2} mb={2} fontSize="xs" color="gray.500">
                💡 Tip: Mention completed tasks using @
                </Text>
                <TaskMentionSystem
                  value={description}
                  context={context}
                  onChange={({ description, context }) => {
                    setDescription(description);
                    setContext(context);
                  }}
                  placeholder="Research climate change initiatives in 2024"
                  maxMentions={3}
                  tasks={tasks}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize="md" fontWeight="semibold" color="#3F536A">Describe the expected output or result of this step</FormLabel>
                <Textarea
                  value={expectedOutput}
                  onChange={(e) => setExpectedOutput(e.target.value)}
                  placeholder="Detailed research with relevant sources"
                  focusBorderColor="purple.100"
                  borderColor="gray.200"
                  _hover={{ borderColor: "gray.300" }}
                  minHeight="40px"
                  maxHeight="200px"
                  resize="vertical"
                  rows={3}
                  overflowY="auto"
                />
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button 
            variant="ghost" 
            mr={3} 
            onClick={onClose}
            _hover={{ bg: 'gray.100', borderRadius: 'full' }}
            borderRadius="full"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            borderRadius="full"
            bg="purple.100"
            color="purple.700"
            _hover={{ bg: 'purple.200' }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default NodeModal;