import React, { useState, useEffect } from 'react';
import { Box, VStack, Heading, Text } from '@chakra-ui/react';
import ContentFeedItem from './ContentFeedItem';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import API_BASE_URL from '../config';
import LoadingSpinner from './LoadingSpinner';

function UserFeed() {
  const [feedTasks, setFeedTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchFeed = async () => {
      if (!user) return;

      try {
        const idToken = await user.getIdToken();
        const response = await axios.get(`${API_BASE_URL}/user/feed`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        setFeedTasks(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user feed:', error);
        setError('Failed to load your feed. Please try again later.');
        setLoading(false);
      }
    };

    fetchFeed();
  }, [user]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <Box>
      <Heading size="lg" mb={4}>Your Feed</Heading>
      <VStack spacing={6} align="stretch">
        {feedTasks.map((feedItem) => (
          <ContentFeedItem
            key={feedItem.task.id}
            task={feedItem.task}
            crew={feedItem.crew}
          />
        ))}
      </VStack>
    </Box>
  );
}

export default UserFeed;