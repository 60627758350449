import React from 'react';
import { Button, Text, Flex, useColorModeValue, IconButton } from '@chakra-ui/react';
import { MdSearch, MdClose } from 'react-icons/md';

const CondensedMinionCard = ({ agent, onRemove, onEdit }) => {
  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  return (
    <Button
      width="100%"
      height="auto"
      py={3}
      px={4}
      justifyContent="space-between"
      variant="outline"
      borderColor="gray.200"
      bg={bgColor}
      onClick={() => onEdit(agent)}
    >
      <Flex direction="column" alignItems="flex-start">
        <Text fontWeight="bold" color={textColor} isTruncated>
          {agent.role}
        </Text>
        {agent.web_search_enabled && (
          <Flex alignItems="center" color="teal.500" mt={2}>
            <MdSearch size={16} />
            <Text fontSize="xs" ml={2}>
              Web search enabled
            </Text>
          </Flex>
        )}
      </Flex>
      <IconButton
        icon={<MdClose />}
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          onRemove(agent);
        }}
        aria-label="Remove agent"
        variant="ghost"
        ml={2}
      />
    </Button>
  );
};

export default CondensedMinionCard;
