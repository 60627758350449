import React, { useState } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import TaskResultCard from './TaskResultCard';

function TaskCarousel({ tasks, onTaskSelect }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTask = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % tasks.length);
  };

  const prevTask = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex - 1 + tasks.length) % tasks.length);
  };

  return (
    <Flex align="center" justify="center" w="100%" px={4} onClick={(e) => e.stopPropagation()}>
      <IconButton
        icon={<ChevronLeftIcon />}
        onClick={prevTask}
        isDisabled={tasks.length <= 1}
        aria-label="Previous task"
        size="xs"
        mr={2}
        isRound
        bg="gray.100"
        _hover={{ bg: "gray.200" }}
      />
      <Box w="100%" maxW="100%">
        {tasks.length > 0 && (
          <TaskResultCard
            task={tasks[currentIndex]}
            isSelected={false}
            onSelect={(e) => onTaskSelect(tasks[currentIndex].id, e)}
          />
        )}
      </Box>
      <IconButton
        icon={<ChevronRightIcon />}
        onClick={nextTask}
        isDisabled={tasks.length <= 1}
        aria-label="Next task"
        size="xs"
        ml={2}
        isRound
        bg="gray.100"
        _hover={{ bg: "gray.200" }}
      />
    </Flex>
  );
}

export default TaskCarousel;