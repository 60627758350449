import React, { useState } from 'react';
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Text, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import ImageGenerationForm from './ImageGenerationForm';
import ImagePreviewCard from './ImagePreviewCard';
import { examplePrompts } from '../ImagePrompts';

function ImageGeneration() {
  const { taskId } = useParams();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Box maxWidth="1200px" margin="auto" mt={8} p={6}>
      <Text fontSize="3xl" fontWeight="bold" mb={6} textAlign="center">Thumbnail Studio</Text>
      <Tabs variant="soft-rounded" colorScheme="purple" onChange={(index) => setActiveTab(index)}>
        <TabList justifyContent="center" mb={6}>
          <Tab>Generate</Tab>
          <Tab>Examples</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
          <VStack spacing={4} align="stretch">
            <Text fontSize="md" fontWeight="semibold" color="gray.600">
              🎨 Unleash your creativity! Describe the image you want to generate being specific about the details and style you're looking for.
              See the Examples tab for inspiration.
            </Text>
            <ImageGenerationForm taskId={taskId} />
          </VStack>
          </TabPanel>
          <TabPanel>
            <Flex flexWrap="wrap" justifyContent="center" gap={8}>
              {examplePrompts.map((example, index) => (
                <Box key={index} flexBasis={['100%', '45%', '30%']} maxWidth={['100%', '45%', '30%']}>
                  <ImagePreviewCard prompt={example.prompt} imageUrl={example.imageUrl} />
                </Box>
              ))}
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default ImageGeneration;