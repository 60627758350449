import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config';
import {
  Box,
  VStack,
  Text,
  Heading,
  Flex,
  useColorModeValue,
  Divider,
  Spinner,
  Center,
  SimpleGrid,
  IconButton,
  Tooltip,
  useToast,
  Spacer,
  Icon,
  Collapse,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import FormattedMarkdown from './FormattedMarkdown';
import MarkdownPreviewComponent from './MarkdownPreview';
import { MdContentCopy, MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import MinionCard from './MinionCard';
import TaskResultCard from './TaskResultCard';

function ShareableTaskDetails() {
  const { id } = useParams();
  const [task, setTask] = useState(null);
  const [crew, setCrew] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();
  const [isMinionsExpanded, setIsMinionsExpanded] = useState(true);

  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const sectionBgColor = useColorModeValue('white', 'gray.600');

  useEffect(() => {
    const fetchTaskAndCrew = async () => {
      setIsLoading(true);
      try {
        const taskResponse = await axios.get(`${API_BASE_URL}/sharedtask/${id}`);
        setTask(taskResponse.data);

        if (taskResponse.data.crew_id) {
          const crewResponse = await axios.get(`${API_BASE_URL}/crews/${taskResponse.data.crew_id}`);
          setCrew(crewResponse.data);
        }
      } catch (error) {
        console.error('Error fetching task or crew details:', error);
        if (error.response && error.response.status === 404) {
          setError('This task is not available or has been unshared.');
        } else {
          setError('Failed to load task details. Please try again later.');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchTaskAndCrew();
    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, [id]);

  const copyCodeToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    toast({
      title: "Code copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const copyResultToClipboard = () => {
    navigator.clipboard.writeText(task.result);
    toast({
      title: "Result copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" color="teal.500" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center height="100vh">
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          borderRadius="md"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Task Not Available
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            {error}
          </AlertDescription>
        </Alert>
      </Center>
    );
  }

  if (!task) {
    return (
      <Center height="100vh">
        <Text>Task not found.</Text>
      </Center>
    );
  }

  return (
    <Box maxWidth="90vw" margin="auto" mt={0} mb={8}>
      <VStack align="stretch" spacing={6} py={4}>
        {task && task.thumbnail_url && (
          <Box width="100%">
            <TaskResultCard task={task} isSelected={false} onSelect={() => {}} />
          </Box>
        )}

        {task.result && (
          <Box
            borderWidth="1px"
            borderRadius="lg"
            borderColor={borderColor}
            p={6}
            boxShadow="sm"
            transition="all 0.2s"
            _hover={{ boxShadow: 'md' }}
            bg={sectionBgColor}
          >
            {/* <Flex justifyContent="flex-end" alignItems="center" mb={4}>
              <Tooltip label="Copy result to clipboard" hasArrow>
                <IconButton
                  icon={<MdContentCopy />}
                  onClick={copyResultToClipboard}
                  aria-label="Copy result"
                  borderRadius="full"
                  bg="transparent"
                  _hover={{ bg: "purple.50", color: "purple.700" }}
                />
              </Tooltip>
            </Flex> */}
            <Box borderRadius="md">
            <MarkdownPreviewComponent initialContent={task.result} taskId={task.id}/>
            </Box>
          </Box>
        )}

        <Divider my={4} borderColor={borderColor} />

        <Heading as="h4" size="md" color='gray.500'>More about this task</Heading>

        <Box
          borderWidth="1px"
          borderRadius="lg"
          borderColor={borderColor}
          p={6}
          boxShadow="sm"
          transition="all 0.2s"
          _hover={{ boxShadow: 'md' }}
          bg={bgColor}
        >
          <VStack align="stretch" spacing={4}>
            <Box>
              <Heading as="h4" size="md" color='gray.500' mb={2}>Task description</Heading>
              <Text color='gray.500' fontSize="md">{task.description}</Text>
            </Box>
            <Box>
              <Heading as="h4" size="md" color='gray.500' mb={2}>Expected output</Heading>
              <Text color='gray.500' fontSize="md">{task.expected_output}</Text>
            </Box>
          </VStack>
        </Box>

        {crew && crew.agents && crew.agents.length > 0 && (
          <Box
            borderWidth="1px"
            borderRadius="lg"
            borderColor={borderColor}
            p={6}
            boxShadow="sm"
            transition="all 0.2s"
            _hover={{ boxShadow: 'md' }}
            bg={bgColor}
          >
            <Flex alignItems="center" mb={4} cursor="pointer" onClick={() => setIsMinionsExpanded(!isMinionsExpanded)}>
              <Heading as="h4" size="md" color='gray.500'>
                <Text as="span" color="purple.500">{crew.agents.length} minion{crew.agents.length !== 1 && 's'}</Text> worked on this task
              </Heading>
              <Spacer />
              <Icon 
                as={isMinionsExpanded ? MdKeyboardArrowUp : MdKeyboardArrowDown} 
                _hover={{ fill: "purple.100" }}
              />
            </Flex>
            <Collapse in={isMinionsExpanded}>
              <SimpleGrid columns={[1, 2, 3]} spacing={6}>
                {crew.agents.map((agent) => (
                  <MinionCard
                    key={agent.id}
                    agent={agent}
                    onEdit={() => {}}
                    toggleWebSearch={() => {}}
                    onDelete={() => {}}
                    isEditable={false}
                    isSelectable={false}
                  />
                ))}
              </SimpleGrid>
            </Collapse>
          </Box>
        )}
      </VStack>
    </Box>
  );
}

export default ShareableTaskDetails;