import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { FaUserSecret } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import GlowingGradientWrapper from './GlowingGradientWrapper';
import MyminionsLogo from './MyminionsLogo';

const EnhancedGlowingWrapper = ({ children, className = '' }) => {
    return (
      <div className={`relative overflow-hidden ${className}`}>
        <div className="absolute inset-0 bg-gradient-to-r from-blue-300 via-purple-300 to-pink-300 opacity-75 blur-2xl animate-pulse"></div>
        <div className="absolute inset-0 bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 opacity-50 animate-pulse" style={{ animationDelay: '-2s' }}></div>
        <div className="absolute inset-0">
          {[...Array(3)].map((_, index) => (
            <div
              key={index}
              className="absolute rounded-full bg-white opacity-30 animate-ripple"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                width: `${Math.random() * 100 + 50}px`,
                height: `${Math.random() * 100 + 50}px`,
                animationDelay: `${index * 2}s`,
              }}
            ></div>
          ))}
        </div>
        <div className="relative z-10">{children}</div>
      </div>
    );
  };

const AuthPage = () => {
  const { signInWithGoogle, signInAnonymous } = useAuth();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      if (error.code !== 'auth/cancelled-popup-request') {
        console.error('Google Sign-In Error:', error);
        // Optionally, show an error message to the user
      }
    }
  };

  return (
    <GlowingGradientWrapper className="min-h-screen w-full flex items-center justify-center" gradientColors={['blue', 'purple', 'pink', 'orange']}>
      <div className="p-12 bg-white bg-opacity-80 backdrop-blur-md rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
        <div className="flex items-center justify-center mb-8 py-2">
          <h2 className="text-3xl font-extrabold text-gray-700 mr-4">Welcome to</h2>
          <MyminionsLogo size="2xl" className="py-1" />
        </div>
        <button 
          onClick={handleGoogleSignIn}
          className="flex items-center justify-center w-full px-6 py-3 mb-4 text-lg font-semibold text-gray-800 bg-white border-2 border-gray-300 rounded-full hover:bg-gray-50 hover:shadow-md transition-all duration-300"
        >
          <FcGoogle className="mr-2 text-2xl" />
          Sign in with Google
        </button>
        <button 
          onClick={signInAnonymous}
          className="flex items-center justify-center w-full px-6 py-3 text-lg font-semibold text-white bg-gray-700 rounded-full hover:bg-gray-600 hover:shadow-md transition-all duration-300"
        >
          <FaUserSecret className="mr-2 text-xl" />
          Sign in anonymously
        </button>
        <p className="mt-6 text-sm text-center text-gray-600">
          By signing in, you agree to our Terms of Service and Privacy Policy.
        </p>
      </div>
    </GlowingGradientWrapper>
  );
};

export default AuthPage;