import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import {
    ReactFlow,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    getBezierPath
} from '@xyflow/react';
import { getAuth } from 'firebase/auth';
import API_BASE_URL, { WS_BASE_URL } from '../config';
import '@xyflow/react/dist/style.css';
import { Button, Box, VStack, Input, Select, Text, Icon, InputGroup, InputLeftElement, Alert, AlertIcon, Flex, useColorModeValue, Heading, useToast, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverFooter, Spinner, Tabs, TabList, TabPanel, TabPanels, Tab, Badge, Tooltip } from '@chakra-ui/react';
import { FaTasks, FaPlay, FaChevronRight, FaChevronLeft, FaClipboardList, FaSave, FaList, FaProjectDiagram, FaEye, FaRegFileAlt } from 'react-icons/fa';
import { AddIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import axios from 'axios';
import NodeModal from './NodeModal';
import MarkdownPreviewComponent from './MarkdownPreview';
import WorkflowResultPanel from './WorkflowResultPanel';

import SourceNode from './SourceNode';
import CustomNode from './CustomNode';
import { ArrowRight, Plus, Link, Play, Repeat } from 'lucide-react';
import { v4 as guid } from 'uuid';
import WorkflowCard from './WorkflowCard';
import { Player } from '@lottiefiles/react-lottie-player';
import emptyStateCards from '../assets/empty_state_cards.json';
import { FiUser } from 'react-icons/fi';



// New custom animated edge component
const AnimatedEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style = {},
    data,
}) => {
    const [edgePath] = getBezierPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    return (
        <path
            id={id}
            style={{
                ...style,
                stroke: useColorModeValue('gray.500', 'gray.300'),
                strokeWidth: 2,
            }}
            className="react-flow__edge-path animated"
            d={edgePath}
        />
    );
};

// Define nodeTypes outside of the component
const nodeTypes = {
    source: SourceNode,
    customnode: CustomNode,
  };
  
  // Define edgeTypes outside of the component
  const edgeTypes = {
    animated: AnimatedEdge,
  };

const initialNodes = [];
const initialEdges = [];

const onConnectStart = (_, { nodeId, handleType }) => {};
const onConnectEnd = (event) => {};

const WorkflowExplanation = () => {
  return (
    <Box bg="pink.50" p={6} borderRadius="xl" boxShadow="md">
      <Heading as="h2" size="lg" mb={6} color="gray.700">How to Use the AI Workflow Designer</Heading>
      <VStack spacing={6} align="stretch">
        <Step 
          icon={<Plus />} 
          title="Add Tasks" 
          description="Create a task by naming it and assigning a minion to handle it"
          bgColor="green.50" 
        />
        <Step 
          icon={<Link />} 
          title="Connect Tasks" 
          description="Link tasks to create a sequence. Each minion will pass context to the next in the chain"
          bgColor="green.100" 
        />
        <Step 
          icon={<Play />} 
          title="Execute Workflow" 
          description="Start the process by clicking the play button to set your minions in motion"
          bgColor="green.200" 
        />
        <Step 
          icon={<Repeat />} 
          title="Refine and Iterate" 
          description="Review results and make adjustments to optimize your workflow"
          bgColor="green.300" 
        />
      </VStack>
    </Box>
  );
};

const Step = ({ icon, title, description, bgColor }) => (
  <Flex align="flex-start" gap={6}>
    <Flex
      w={12}
      h={12}
      bg={bgColor}
      color="green.700"
      rounded="full"
      align="center"
      justify="center"
      p={3}
      flexShrink={0}
    >
      {React.cloneElement(icon, { size: 24 })}
    </Flex>
    <Box>
      <Text fontSize="lg" fontWeight="semibold" color="gray.700">{title}</Text>
      <Text fontSize="md" color="gray.600">{description}</Text>
    </Box>
  </Flex>
);

const InteractiveCanvas = ({ crew, crewId, onClose }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [taskName, setTaskName] = useState('');
  const [selectedAgentId, setSelectedAgentId] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [taskExpectedOutput, setTaskExpectedOutput] = useState('');
  const [selectedNode, setSelectedNode] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workflowResult, setWorkflowResult] = useState('');
  const [isExecuting, setIsExecuting] = useState(false);
  const [showResultPanel, setShowResultPanel] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const websocket = useRef(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();
  const [hoveredNodeId, setHoveredNodeId] = useState(null);
  const hideToolbarTimeout = useRef(null);
  const [statusUpdates, setStatusUpdates] = useState([]);
  const [wsReconnectAttempts, setWsReconnectAttempts] = useState(0);
  const [wsErrorOccurred, setWsErrorOccurred] = useState(false);
  const MAX_RECONNECT_ATTEMPTS = 5;
  const RECONNECT_INTERVAL = 3000; // 3 seconds
  const [workflowName, setWorkflowName] = useState('');
  const [isWorkflowNamePopoverOpen, setIsWorkflowNamePopoverOpen] = useState(false);
  const [crewWorkflows, setCrewWorkflows] = useState([]);
  const [isLoadingWorkflows, setIsLoadingWorkflows] = useState(false);
  const [agentNameCache, setAgentNameCache] = useState({});
  const [agentRoles, setAgentRoles] = useState({});
  const [workflowStepResults, setWorkflowStepResults] = useState([]);
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [nodeLabels, setNodeLabels] = useState({});
  const [isWorkflowModified, setIsWorkflowModified] = useState(false);
  const [isAgentDropdownOpen, setIsAgentDropdownOpen] = useState(false);
  const [taskId, setTaskId] = useState(null);

  // Add a new state to track the currently loaded workflow
  const [currentWorkflow, setCurrentWorkflow] = useState(null);

  // Modify the activeTab state to use a function for initialization
  const [activeTab, setActiveTab] = useState(() => {
    // If there's no workflow result, default to the Workflows tab (index 1)
    return workflowResult ? 0 : 1;
  });

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({ ...params, animated: true }, eds)),
    [setEdges]
  );

  const isValidConnection = useCallback((connection) => {
    const sourceNode = nodes.find(node => node.id === connection.source);
    const targetNode = nodes.find(node => node.id === connection.target);
    
    // Check if both nodes exist
    if (!sourceNode || !targetNode) return false;

    // Prevent self-connections
    if (connection.source === connection.target) {
      return false;
    }
    
    // Check for cycles (simplified)
    const visited = new Set();
    const hasCycle = (nodeId) => {
      if (visited.has(nodeId)) return false;
      visited.add(nodeId);
      const outgoingEdges = edges.filter(edge => edge.source === nodeId);
      for (let edge of outgoingEdges) {
        if (edge.target === connection.source || hasCycle(edge.target)) {
          return true;
        }
      }
      return false;
    };
    
    if (hasCycle(connection.target)) {
      return false;
    }
    
    return true;
  }, [nodes, edges]);

  // Update the onNodeMouseEnter function to ensure only one toolbar is visible
  const onNodeMouseEnter = useCallback((event, node) => {
    if (hideToolbarTimeout.current) {
        clearTimeout(hideToolbarTimeout.current);
    }
    setHoveredNodeId(node.id);
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        data: { 
          ...n.data, 
          toolbarVisible: n.id === node.id 
        },
      }))
    );
  }, [setNodes]);

  // Update the onNodeMouseLeave function to hide the toolbar correctly
  const onNodeMouseLeave = useCallback((event, node) => {
    hideToolbarTimeout.current = setTimeout(() => {
      if (hoveredNodeId === node.id) {
        setHoveredNodeId(null);
        setNodes((nds) =>
          nds.map((n) => ({
            ...n,
            data: { 
              ...n.data, 
              toolbarVisible: false 
            },
          }))
        );
      }
    }, 300); // 300ms delay before hiding the toolbar
  }, [setNodes, hoveredNodeId]);

  const onToolbarMouseEnter = useCallback(() => {
    if (hideToolbarTimeout.current) {
      clearTimeout(hideToolbarTimeout.current);
    }
  }, []);

  const onToolbarMouseLeave = useCallback((nodeId) => {
    hideToolbarTimeout.current = setTimeout(() => {
      setHoveredNodeId(null);
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === nodeId) {
            return {
              ...n,
              data: { ...n.data, toolbarVisible: false },
            };
          }
          return n;
        })
      );
    }, 300);
  }, [setNodes]);

  const onNodeEdit = useCallback((nodeId) => {
    setNodes(currentNodes => {
      const node = currentNodes.find((n) => n.id === nodeId);
      if (node) {
        setSelectedNode(node);
        setIsModalOpen(true);
      } else {
        console.error(`Node with id ${nodeId} not found`);
      }
      return currentNodes; // Return the current nodes state unchanged
    });
  }, []);

  const onNodeRemove = useCallback((nodeId) => {
    setNodes((nds) => {
      const updatedNodes = nds.filter((n) => n.id !== nodeId);
      // If the removed node was a source, update the first remaining node to be the new source
      if (nds.find(n => n.id === nodeId)?.type === 'source' && updatedNodes.length > 0) {
        updatedNodes[0] = { ...updatedNodes[0], type: 'source' };
      }
      return updatedNodes;
    });
    setEdges((eds) => eds.filter((e) => e.source !== nodeId && e.target !== nodeId));
  }, [setNodes, setEdges]);

  const onNodeClick = useCallback((event, node) => {
    setNodes(currentNodes => {
      const clickedNode = currentNodes.find((n) => n.id === node.id);
      if (clickedNode) {
        setSelectedNode(clickedNode);
        setIsModalOpen(true);
      } else {
        console.error(`Node with id ${node.id} not found`);
      }
      return currentNodes; // Return the current nodes state unchanged
    });
  }, []);

  const addTask = () => {
    if (!taskName || !selectedAgentId) return;

    const selectedAgent = crew.agents.find(agent => agent.id === selectedAgentId);
    if (!selectedAgent) return;

    setNodes((nds) => {
      const newNodeType = nds.some(node => node.type === 'source') ? 'customnode' : 'source';
      const newNode = {
        id: `task_${guid()}`,
        type: newNodeType,
        position: { x: Math.random() * 300, y: Math.random() * 300 },
        data: { 
          label: taskName,
          agentId: selectedAgent.id,
          agentName: selectedAgent.role,
          description: taskDescription,
          expectedOutput: taskExpectedOutput,
          context: [],
          toolbarVisible: false,
          onEdit: () => onNodeEdit(newNode.id),
          onRemove: onNodeRemove,
          onToolbarMouseEnter: onToolbarMouseEnter,
          onToolbarMouseLeave: onToolbarMouseLeave,
        },
      };
      return [...nds, newNode];
    });

    setTaskName('');
    setSelectedAgentId('');
    setTaskDescription('');
    setTaskExpectedOutput('');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedNode(null);
  };

  const updateNodeData = (updatedData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNode.id) {
          return {
            ...node,
            data: {
              ...node.data,
              ...updatedData,
            },
          };
        }
        return node;
      })
    );
    closeModal();
  };

  const connectWebSocket = useCallback((idToken, graphData) => {
    if (wsErrorOccurred) return; // Don't reconnect if an error occurred

    websocket.current = new WebSocket(`${WS_BASE_URL}/ws/execute-workflow/${crewId}`);

    websocket.current.onopen = () => {
      console.log('WebSocket connection established');
      setWsReconnectAttempts(0);
      websocket.current.send(JSON.stringify({
        token: idToken,
        graphData: graphData
      }));
    };

    websocket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.is_complete) {
        setTaskId(data.task_id);
        setWorkflowResult(data.result.final_output);
        setActiveTab(0); // Switch to the Result tab
        setWorkflowStepResults(data.result.steps);
        setIsExecuting(false);
        websocket.current.close();
      } else if (data.statusUpdate) {
        setStatusUpdates(prevUpdates => [...prevUpdates, data.statusUpdate]);
      } else {
        // Handle other intermediate results if needed
        if ("error" in data) {
          toast({
            title: 'Workflow Error',
            description: 'An error occurred while executing your workflow',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          setError(data.error);
          setIsExecuting(false);
          websocket.current.close();
        }
      }
    };

    websocket.current.onerror = (error) => {
      console.error('WebSocket error:', error);
      setWsErrorOccurred(true);
      setIsExecuting(false);
      setError('An error occurred while executing the workflow.');
      toast({
        title: 'Execution Error',
        description: 'An error occurred while executing the workflow.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    };

    websocket.current.onclose = (event) => {
      console.log('WebSocket connection closed');
      if (!event.wasClean && !wsErrorOccurred) {
        // Attempt to reconnect
        if (wsReconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
          setTimeout(() => {
            setWsReconnectAttempts(prev => prev + 1);
            connectWebSocket(idToken, graphData);
          }, RECONNECT_INTERVAL);
        } else {
          setError('Unable to maintain connection. Please try again later.');
          setIsExecuting(false);
        }
      }
    };
  }, [crewId, wsReconnectAttempts, wsErrorOccurred, toast]);

  const executeWorkflow = async () => {
    if (!isGraphValid()) {
      toast({
        title: 'Invalid Workflow',
        description: 'The workflow is not valid. Ensure there is one source node and all nodes are connected.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsExecuting(true);
    setWorkflowResult('');
    setShowResultPanel(true);
    setError(null);
    setWsErrorOccurred(false);
    setWsReconnectAttempts(0);
    setActiveTab(0); // Switch to the Result tab

    const graphData = {
      nodes: nodes.map(node => ({
        id: node.id,
        type: node.type,
        data: {
          taskName: node.data.label,
          agentId: node.data.agentId,
          description: node.data.description || '',
          expectedOutput: node.data.expectedOutput || '',
          context: node.data.context || []
        }
      })),
      edges: edges.map(edge => ({
        id: edge.id,
        source: edge.source,
        target: edge.target
      }))
    };

    // Set nodeLabels here, using the graphData
    const labels = {};
    graphData.nodes.forEach(node => {
      labels[node.id] = node.data.taskName;
    });
    setNodeLabels(labels);

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      
      const idToken = await user.getIdToken();
      
      connectWebSocket(idToken, graphData);

    } catch (error) {
      console.error('Error executing workflow:', error);
      setIsExecuting(false);
      setError('An error occurred while executing the workflow.');
      toast({
        title: 'Execution Error',
        description: 'An error occurred while executing the workflow.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const saveTaskResult = async () => {
    setIsSaving(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      
      const idToken = await user.getIdToken();
      
      await axios.post(`${API_BASE_URL}/save-task-result`, {
        result: workflowResult
      }, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      
      console.log('Task result saved successfully');
      setIsSaving(false);
      // Optionally, you can add some user feedback here
    } catch (error) {
      console.error('Error saving task result:', error);
      setIsSaving(false);
      // Optionally, you can add some error feedback here
    }
  };

  const saveWorkflow = async () => {
    if (!workflowName) {
      toast({
        title: 'Workflow Name Required',
        description: 'Please enter a name for your workflow.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsSaving(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      
      const idToken = await user.getIdToken();

      const workflowData = {
        name: workflowName,
        graph: {
          steps: {},
          edges: {}
        },
        crew_id: crewId
      };

      // Convert nodes to steps
      nodes.forEach(node => {
        workflowData.graph.steps[node.id] = {
          id: node.id,
          type: node.type,
          name: node.data.label,
          description: node.data.description || '',
          expected_output: node.data.expectedOutput || '',
          agents: [node.data.agentId],
          context: node.data.context || [],
          position: {
            x: node.position.x,
            y: node.position.y
          }
        };
      });

      // Convert edges
      edges.forEach(edge => {
        workflowData.graph.edges[edge.id] = {
          id: edge.id,
          source: edge.source,
          destination: edge.target
        };
      });

      const response = await axios.post(`${API_BASE_URL}/workflows`, workflowData, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      // Update the crewWorkflows state with the new workflow, sorted by created_at desc
      const newWorkflow = response.data;
      setCrewWorkflows(prevWorkflows => [...prevWorkflows, newWorkflow].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));

      setIsSaving(false);
      toast({
        title: 'Workflow Saved',
        description: 'Your workflow has been successfully saved.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving workflow:', error);
      setIsSaving(false);
      toast({
        title: 'Error Saving Workflow',
        description: 'An error occurred while saving your workflow. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchCrewWorkflowsAndAgentRoles = useCallback(async () => {
    setIsLoadingWorkflows(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      
      const idToken = await user.getIdToken();
      
      // Fetch workflows
      const workflowsResponse = await axios.get(`${API_BASE_URL}/crews/${crewId}/workflows`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      
      setCrewWorkflows(workflowsResponse.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));

      // Extract all unique agent IDs from the workflows
      const agentIds = new Set();
      workflowsResponse.data.forEach(workflow => {
        Object.values(workflow.graph.steps).forEach(step => {
          step.agents.forEach(agentId => agentIds.add(agentId));
        });
      });

      // Fetch agent roles
      const agentRolesResponse = await axios.post(`${API_BASE_URL}/agents-roles`, 
        Array.from(agentIds),
        {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        }
      );
      
      setAgentRoles(agentRolesResponse.data);

    } catch (error) {
      console.error('Error fetching crew workflows and agent roles:', error);
      toast({
        title: 'Error Fetching Data',
        description: 'An error occurred while fetching the crew workflows and agent roles.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingWorkflows(false);
    }
  }, [crewId, toast]);

  useEffect(() => {
    fetchCrewWorkflowsAndAgentRoles();
  }, [fetchCrewWorkflowsAndAgentRoles]);

  // Function to compare current graph state with loaded workflow
  const compareGraphWithLoadedWorkflow = useCallback(() => {
    if (!currentWorkflow) return true; // If no workflow is loaded, consider it modified

    const currentGraph = {
      nodes: nodes.map(node => ({
        id: node.id,
        type: node.type,
        position: node.position,
        data: {
          label: node.data.label,
          agentId: node.data.agentId,
          description: node.data.description,
          expectedOutput: node.data.expectedOutput,
          context: node.data.context,
        },
      })),
      edges: edges.map(edge => ({
        id: edge.id,
        source: edge.source,
        target: edge.target,
      })),
    };

    const loadedGraph = {
      nodes: Object.values(currentWorkflow.graph.steps).map(step => ({
        id: step.id,
        type: step.type,
        position: step.position,
        data: {
          label: step.name,
          agentId: step.agents[0],
          description: step.description,
          expectedOutput: step.expected_output,
          context: step.context,
        },
      })),
      edges: Object.values(currentWorkflow.graph.edges).map(edge => ({
        id: edge.id,
        source: edge.source,
        target: edge.destination,
      })),
    };

    return JSON.stringify(currentGraph) !== JSON.stringify(loadedGraph);
  }, [currentWorkflow, nodes, edges]);

  // Update isWorkflowModified when nodes or edges change
  useEffect(() => {
    setIsWorkflowModified(compareGraphWithLoadedWorkflow());
  }, [nodes, edges, compareGraphWithLoadedWorkflow]);

  const loadWorkflow = useCallback((workflow) => {
    // Convert the workflow data to the format expected by ReactFlow
    setIsWorkflowNamePopoverOpen(false);
    const newNodes = Object.values(workflow.graph.steps).map((step) => {
      const agentId = step.agents[0];
      const agentRole = agentRoles[agentId] || 'Unknown Minion';
      return {
        id: step.id,
        type: step.type,
        position: step.position,
        data: {
          label: step.name,
          agentId: agentId,
          agentName: agentRole,
          description: step.description,
          expectedOutput: step.expected_output,
          context: step.context,
          toolbarVisible: false,
          onEdit: () => onNodeEdit(step.id),
          onRemove: onNodeRemove,
          onToolbarMouseEnter: onToolbarMouseEnter,
          onToolbarMouseLeave: onToolbarMouseLeave,
        },
      };
    });

    const newEdges = Object.values(workflow.graph.edges).map(edge => ({
      id: edge.id,
      source: edge.source,
      target: edge.destination,
      animated: true,
    }));

    setNodes(newNodes);
    setEdges(newEdges);
    setCurrentWorkflow(workflow);
    setWorkflowName(workflow.name);
    setIsWorkflowModified(false);
  }, [agentRoles, onNodeEdit, onNodeRemove, onToolbarMouseEnter, onToolbarMouseLeave, setEdges, setNodes]);

  const updateWorkflow = async () => {
    if (!currentWorkflow) {
      console.error('No workflow loaded to update');
      return;
    }

    setIsSaving(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      
      const idToken = await user.getIdToken();

      const workflowData = {
        name: workflowName,
        graph: {
          steps: {},
          edges: {}
        },
        crew_id: crewId
      };

      // Convert nodes to steps
      nodes.forEach(node => {
        workflowData.graph.steps[node.id] = {
          id: node.id,
          type: node.type,
          name: node.data.label,
          description: node.data.description || '',
          expected_output: node.data.expectedOutput || '',
          agents: [node.data.agentId],
          context: node.data.context || [],
          position: {
            x: node.position.x,
            y: node.position.y
          }
        };
      });

      // Convert edges
      edges.forEach(edge => {
        workflowData.graph.edges[edge.id] = {
          id: edge.id,
          source: edge.source,
          destination: edge.target
        };
      });

      const response = await axios.put(`${API_BASE_URL}/workflows/${currentWorkflow.id}`, workflowData, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      // Update the crewWorkflows state with the updated workflow
      setCrewWorkflows(prevWorkflows => 
        prevWorkflows.map(w => w.id === currentWorkflow.id ? response.data : w)
      );

      setCurrentWorkflow(response.data);

      setIsSaving(false);
      toast({
        title: 'Workflow Updated',
        description: 'Your workflow has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsWorkflowModified(false);
    } catch (error) {
      console.error('Error updating workflow:', error);
      setIsSaving(false);
      toast({
        title: 'Error Updating Workflow',
        description: 'An error occurred while updating your workflow. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSaveWorkflow = () => {
    setIsWorkflowNamePopoverOpen(true);
  };

  const saveAsNewWorkflow = () => {
    setWorkflowName('');
    setCurrentWorkflow(null);
    setIsWorkflowModified(true);
    // Keep the popover open, but switch to the "new workflow" view
    setIsWorkflowNamePopoverOpen(true);
  };

  useEffect(() => {
    return () => {
      if (websocket.current) {
        websocket.current.close();
      }
      if (hideToolbarTimeout.current) {
        clearTimeout(hideToolbarTimeout.current);
      }
    };
  }, []);

  // Updated color mode values
  const bgColor = '#3F536A';
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.500');
  const inputBgColor = useColorModeValue('white', 'gray.600');
  const pageBgColor = useColorModeValue('pink.50', 'gray.900'); // New pastel cream background

  // New function to check if the graph is valid
  const isGraphValid = useCallback(() => {
    if (nodes.length === 0) return false;
    
    const sourceNodes = nodes.filter(node => node.type === 'source');
    if (sourceNodes.length !== 1) return false;
    
    // Check if all nodes are connected
    const nodeSet = new Set(nodes.map(node => node.id));
    const connectedNodes = new Set();
    connectedNodes.add(sourceNodes[0].id);

    let edgesToCheck = edges.filter(edge => edge.source === sourceNodes[0].id);
    while (edgesToCheck.length > 0) {
      const newEdges = [];
      for (const edge of edgesToCheck) {
        if (!connectedNodes.has(edge.target)) {
          connectedNodes.add(edge.target);
          newEdges.push(...edges.filter(e => e.source === edge.target));
        }
      }
      edgesToCheck = newEdges;
    }

    return connectedNodes.size === nodeSet.size;
  }, [nodes, edges]);

  const deleteWorkflow = async (workflowId) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      
      const idToken = await user.getIdToken();
      
      await axios.delete(`${API_BASE_URL}/workflows/${workflowId}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      
      // Remove the deleted workflow from the state
      setCrewWorkflows(prevWorkflows => prevWorkflows.filter(w => w.id !== workflowId));
      
      toast({
        title: 'Workflow Deleted',
        description: 'The workflow has been successfully deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting workflow:', error);
      toast({
        title: 'Error Deleting Workflow',
        description: 'An error occurred while deleting the workflow. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction={{ base: "column", lg: "row" }} minH={{ base: "auto", lg: "60vh" }} bg={pageBgColor}>
      <VStack spacing={6} align="stretch" bg={bgColor} p={6} flex={{ base: "1", lg: showResultPanel ? "1" : "2" }} overflowY="auto" borderRadius="xl">
        <Button
          leftIcon={showExplanation ? <ChevronUpIcon color="purple.200" /> : <ChevronDownIcon color="purple.200" />}
          onClick={() => setShowExplanation(!showExplanation)}
          variant="outline"
          borderColor="purple.200"
          borderRadius="full"
          width="fit-content"
          _hover={{ bg: "purpleAlpha.200", color: "white" }}
        >
          <Text color="purple.200">{showExplanation ? "Hide" : "Show"} workflow guide</Text>
        </Button>
        
        {showExplanation && <WorkflowExplanation />}

        <Flex direction={{ base: "column", md: "row" }} gap={4} align="center">
          <InputGroup size="md">
            <Input 
              placeholder="Workflow name" 
              value={workflowName} 
              onChange={(e) => setWorkflowName(e.target.value)}
              bg={inputBgColor}
              borderColor={borderColor}
              borderRadius="lg"
              _hover={{ borderColor: "#3F536A" }}
              _focus={{ borderColor: "#3F536A", boxShadow: "0 0 0 1px #3F536A" }}
              maxW="300px"
            />
          </InputGroup>
        </Flex>

        <Flex direction={{ base: "column", md: "row" }} gap={4} align="center">
          <InputGroup size="md" flex={{ base: "1", md: "2" }}>
            <InputLeftElement pointerEvents="none">
              <Icon as={FaTasks} color="#3F536A" />
            </InputLeftElement>
            <Input 
              placeholder="Step name" 
              value={taskName} 
              onChange={(e) => setTaskName(e.target.value)}
              bg={inputBgColor}
              borderColor={borderColor}
              borderRadius="lg"
              _hover={{ borderColor: "#3F536A" }}
              _focus={{ borderColor: "#3F536A", boxShadow: "0 0 0 1px #3F536A" }}
            />
          </InputGroup>
          <Box position="relative" flex={{ base: "1", md: "3" }}>
            <Flex
              alignItems="center"
              borderWidth={1}
              borderColor={borderColor}
              borderRadius="lg"
              bg={inputBgColor}
              p={2}
              cursor="pointer"
              onClick={() => setIsAgentDropdownOpen(!isAgentDropdownOpen)}
              _hover={{ borderColor: "#3F536A" }}
              minW="150px"
            >
              <FiUser style={{ marginRight: '8px' }} />
              <Text>{selectedAgentId ? crew.agents.find(agent => agent.id === selectedAgentId)?.role : "Assign to..."}</Text>
            </Flex>
            {isAgentDropdownOpen && (
              <Box
                position="absolute"
                zIndex={10}
                w="100%"
                minW="150px"
                mt={1}
                p={2}
                bg="white"
                borderRadius="md"
                boxShadow="md"
                border="1px"
                borderColor="gray.200"
              >
                {crew.agents.map((agent) => (
                  <Box
                    key={agent.id}
                    fontSize="md"
                    fontWeight="semibold"
                    p={2}
                    _hover={{ bg: "pink.50", borderRadius: "md" }}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedAgentId(agent.id);
                      setIsAgentDropdownOpen(false);
                    }}
                  >
                    {agent.role}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <IconButton
            icon={<AddIcon />}
            aria-label="Add task"
            onClick={addTask}
            size="md"
            borderRadius="full"
            bg="pink.100"
            color="gray.700"
            _hover={{ transform: "translateY(-2px)", boxShadow: "lg", bg: "pink.200" }}
            transition="all 0.2s"
            p={3}
          />
        </Flex>

        <Box position="relative" width="100%" paddingBottom="75%" borderRadius="xl" overflow="hidden" boxShadow="xl" bg={cardBgColor}>
          <Box position="absolute" top={0} left={0} right={0} bottom={0}>
            <Flex position="absolute" top={4} right={4} zIndex={10} gap={2}>
              {nodes.length > 0 && (
                <Popover
                  isOpen={isWorkflowNamePopoverOpen}
                  onClose={() => setIsWorkflowNamePopoverOpen(false)}
                  placement="bottom-end"
                  closeOnBlur={true}
                >
                  <PopoverTrigger>
                    <IconButton
                      aria-label="Save workflow"
                      icon={<FaSave />}
                      colorScheme="blue"
                      size="lg"
                      borderRadius="full"
                      boxShadow="lg"
                      _hover={{ transform: "scale(1.05)" }}
                      transition="all 0.2s"
                      onClick={handleSaveWorkflow}
                      isLoading={isSaving}
                    />
                  </PopoverTrigger>
                  <PopoverContent width="300px" boxShadow="xl" borderRadius="md">
                    <PopoverBody>
                      {currentWorkflow ? (
                        <VStack spacing={3} align="stretch">
                          <Button
                            width="100%"
                            onClick={updateWorkflow}
                            colorScheme="gray"
                            size="md"
                            borderRadius="md"
                            isDisabled={!isWorkflowModified}
                          >
                            Update {currentWorkflow.name}
                          </Button>
                          <Button
                            width="100%"
                            onClick={saveAsNewWorkflow}
                            colorScheme="teal"
                            size="md"
                            borderRadius="md"
                          >
                            Save as new workflow
                          </Button>
                        </VStack>
                      ) : (
                        <VStack spacing={3} align="stretch">
                          <Input
                            placeholder="Enter workflow name"
                            value={workflowName}
                            onChange={(e) => setWorkflowName(e.target.value)}
                            size="md"
                            borderRadius="md"
                            borderColor="gray.300"
                            _hover={{ borderColor: "blue.500" }}
                            _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce" }}
                          />
                          <Button
                            width="100%"
                            onClick={() => {
                              saveWorkflow();
                              setIsWorkflowNamePopoverOpen(false);
                            }}
                            bg="gray.200"
                            size="md"
                            borderRadius="md"
                            isDisabled={!workflowName.trim()}
                          >
                            Save Workflow
                          </Button>
                        </VStack>
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
              {isGraphValid() && (
                <IconButton
                  aria-label="Execute workflow"
                  icon={<FaPlay />}
                  colorScheme="green"
                  size="lg"
                  borderRadius="full"
                  boxShadow="lg"
                  _hover={{ transform: "scale(1.05)" }}
                  transition="all 0.2s"
                  onClick={executeWorkflow}
                  isLoading={isExecuting}
                />
              )}
            </Flex>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              isValidConnection={isValidConnection}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onConnectStart={onConnectStart}
              onConnectEnd={onConnectEnd}
              onNodeMouseEnter={onNodeMouseEnter}
              onNodeMouseLeave={onNodeMouseLeave}
              onNodeClick={onNodeClick}  // Add this line
              selectNodesOnDrag={false}
              className="validationflow"
              fitView={true}
              onNodesDelete={(deletedNodes) => {
                deletedNodes.forEach(node => onNodeRemove(node.id));
              }}
            >
              <Controls />
              <Background variant="dots" gap={30} size={1.8} color="rgba(0,0,0,0.3)" />
            </ReactFlow>
          </Box>
        </Box>
      </VStack>
      
      <Box width={{ base: "100%", lg: showResultPanel ? "60%" : "40%" }} p={4} mt={{ base: 4, lg: 0 }}>
        <Tabs 
          index={activeTab} 
          onChange={(index) => setActiveTab(index)}
          variant="soft-rounded"
          colorScheme="orange"
          align="center"
          mt={4}
        >
          <TabList>
            <Tab >
              <FaRegFileAlt style={{ marginRight: '8px' }} />
              Result
            </Tab>
            <Tab >
              <FaProjectDiagram style={{ marginRight: '8px' }} />
              Workflows
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {showResultPanel ? (
                <WorkflowResultPanel
                  workflowResult={workflowResult}
                  stepResults={workflowStepResults}
                  selectedStepId={selectedStepId}
                  onSave={saveTaskResult}
                  onClose={() => setShowResultPanel(false)}
                  isSaving={isSaving}
                  isLoading={isExecuting}
                  error={error}
                  statusUpdates={statusUpdates}
                  nodeLabels={nodeLabels}
                  taskId={taskId}
                />
              ) : (
                <Box 
                  borderWidth={1} 
                  borderRadius="lg" 
                  p={6} 
                  bg="gray.100"
                  boxShadow="sm"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box width="100%" maxWidth="400px" height="auto" mb={4}>
                    <Player
                      src={emptyStateCards}
                      loop
                      autoplay
                      style={{ width: '100%', height: '100%', maxHeight: '300px' }}
                    />
                  </Box>
                  <Text fontSize="lg" fontWeight="medium" color="gray.600">
                    Nothing here yet!
                  </Text>
                  <Text fontSize="md" color="gray.500" mt={2}>
                    Execute a workflow and see the output of each step here 🚀
                  </Text>
                </Box>
              )}
            </TabPanel>
            <TabPanel>
              <VStack spacing={4} align="stretch">
                {isLoadingWorkflows ? (
                  <Spinner />
                ) : crewWorkflows.length > 0 ? (
                  crewWorkflows.map((workflow) => (
                    <WorkflowCard
                      key={workflow.id}
                      workflow={workflow}
                      onClick={() => loadWorkflow(workflow)}
                      onDelete={deleteWorkflow}
                    />
                  ))
                ) : (
                  <Box 
                    borderWidth={1} 
                    borderRadius="lg" 
                    p={6} 
                    bg="gray.100"
                    boxShadow="sm"
                    textAlign="center"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon as={FaProjectDiagram} w={10} h={10} color="orange.400" mb={4} />
                    <Text fontSize="lg" fontWeight="medium" color="gray.600">
                      No workflows yet!
                    </Text>
                    <Text fontSize="md" color="gray.500" mt={2}>
                      Create a new workflow in the Interactive Canvas to get started 🚀
                    </Text>
                  </Box>
                )}
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      
      {selectedNode && (
        <NodeModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedNode(null);
          }}
          onSave={updateNodeData}
          initialData={selectedNode.data}
          nodeType={selectedNode.type}
          tasks={crew.tasks}
        />
      )}
    </Flex>
  );
};

export default InteractiveCanvas;