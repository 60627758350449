import { useState, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import API_BASE_URL from '../config';
import { useToast } from '@chakra-ui/react';

export function useFollowCrew(initialIsFollowing, initialFollowersCount, crewId) {
  const [isFollowing, setIsFollowing] = useState(initialIsFollowing);
  const [followersCount, setFollowersCount] = useState(initialFollowersCount);
  const { user } = useAuth();
  const toast = useToast();

  const handleFollowToggle = useCallback(async () => {
    if (!user) {
      toast({
        title: "Please log in",
        description: "You need to be logged in to follow or unfollow this team.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const idToken = await user.getIdToken();
      const endpoint = isFollowing ? 'unfollow' : 'follow';
      await axios.post(`${API_BASE_URL}/crews/${crewId}/${endpoint}`, {}, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setIsFollowing(!isFollowing);
      setFollowersCount(prevCount => isFollowing ? prevCount - 1 : prevCount + 1);
      toast({
        title: isFollowing ? "Team unfollowed" : "Team followed",
        description: isFollowing ? "You are no longer following this team." : "You are now following this team.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`Error ${isFollowing ? 'unfollowing' : 'following'} team:`, error);
      toast({
        title: "Error",
        description: `Failed to ${isFollowing ? 'unfollow' : 'follow'} the team. Please try again.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [isFollowing, user, crewId, toast]);

  return { isFollowing, followersCount, handleFollowToggle };
}