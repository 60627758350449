import React, { useState, useEffect } from 'react';
import { Box, Image, Text, VStack, Skeleton, AspectRatio, Flex, Avatar, Heading } from '@chakra-ui/react';
import ReadingTimeCapsule from './ReadingTimeCapsule';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/dateUtils';

function ContentFeedItem({ task, crew }) {
  const [imageLoaded, setImageLoaded] = useState(true);
  const [imageUrl, setImageUrl] = useState(task.thumbnail_url);
  const navigate = useNavigate();

  useEffect(() => {
    setImageLoaded(false);
    setImageUrl(task.thumbnail_url);
  }, [task.thumbnail_url]);

  const parseHeadline = (result) => {
    const lines = result.split('\n');
    const headingLine = lines.find(line => /^#+\s/.test(line));
    if (headingLine) {
      return headingLine.replace(/^#+\s*/, '').trim();
    }
    const boldLine = lines.find(line => /^\*\*.*\*\*$/.test(line));
    if (boldLine) {
      return boldLine.replace(/^\*\*|\*\*$/g, '').trim();
    }
    return result.replace(/^-+\s*/, '').slice(0, 100).trim() || 'No headline found';
  };

  const headline = parseHeadline(task.result).replace(/^\*+|\*+$/g, '').replace(/^_+|_+$/g, '');

  const renderImage = () => {
    if (!imageUrl) {
      return null; // Don't render anything if there's no image URL
    }

    return (
      <AspectRatio ratio={4 / 3} width="100%" minHeight="150px" maxHeight="250px">
        <Skeleton isLoaded={imageLoaded} borderRadius="md">
          <Image 
            src={imageUrl} 
            alt={headline} 
            objectFit="cover"
            width="100%" 
            height="100%"
            borderRadius="md"
            onLoad={() => setImageLoaded(true)}
            onError={() => setImageLoaded(true)}
          />
        </Skeleton>
      </AspectRatio>
    );
  };

  const handleClick = () => {
    navigate(`/sharedtask/${task.id}`);
  };

  const handleCrewClick = (e) => {
    e.stopPropagation(); // Prevent the task details from opening
    navigate(`/public-crews/${crew.id}`);
  };

  return (
    <Box 
      borderWidth={1}
      borderColor="gray.200"
      borderRadius="lg" 
      overflow="hidden" 
      boxShadow="sm"
      position="relative"
      onClick={handleClick}
      width="100%"
      maxWidth="100%"
      bg="gray.700"
      transition="all 0.3s ease-in-out"
      _hover={{ 
        boxShadow: "xl",
        transform: "translateY(-5px) scale(1.02)",
      }}
      cursor="pointer"
    >
      <Flex p={4} alignItems="center">
        <Flex alignItems="center">
          <Avatar src={crew.avatar} name={crew.name} size="sm" mr={2} />
          <Text 
            fontSize="sm" 
            fontWeight="bold" 
            color="gray.200"
            onClick={handleCrewClick}
            cursor="pointer"
            _hover={{ textDecoration: "underline" }}
          >
            {crew.name}
          </Text>
        </Flex>
        <Flex alignItems="leading">
          <Text fontSize="sm" color="gray.400" mx={2}>
            •
          </Text>
          <Text fontSize="sm" color="gray.400">
            {formatDate(new Date(task.updated_at))}
          </Text>
        </Flex>
      </Flex>
      {imageUrl && (
        <Box px={4}>
          {renderImage()}
        </Box>
      )}
      
      <VStack 
        p={4} 
        align="start" 
        bg="gray.700" 
        minHeight="60px"
        width="100%" 
        justify="flex-start"
        spacing={2}
      >
        <Heading as="h3" size="sm" fontWeight="semibold" color="gray.100" noOfLines={2}>
          {headline}
        </Heading>
        <ReadingTimeCapsule text={task.result} />
      </VStack>
    </Box>
  );
}

export default ContentFeedItem;