import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Box, Textarea, Flex, Tag, TagLabel, TagCloseButton, Text, TagLeftIcon } from '@chakra-ui/react';
import { FiFileText } from 'react-icons/fi';
import { useToast } from '@chakra-ui/react';

const extractHeadline = (result) => {
  const lines = result.split('\n');
  const headingLine = lines.find(line => /^#+\s/.test(line));
  if (headingLine) {
    return headingLine.replace(/^#+\s*/, '').trim();
  }
  const boldLine = lines.find(line => /^\*\*.*\*\*$/.test(line));
  if (boldLine) {
    return boldLine.replace(/^\*\*|\*\*$/g, '').trim();
  }
  return result.replace(/^-+\s*/, '').slice(0, 200).trim() || 'No headline found';
};

const TaskMentionSystem = ({ value, context, onChange, placeholder, maxMentions = 3, tasks = [] }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [mentions, setMentions] = useState([]);
  const [inputValue, setInputValue] = useState(value);
  const toast = useToast();

  // Synchronize internal state with props
  useEffect(() => {
    setInputValue(value);
    if (Array.isArray(context)) {
      setMentions(context.map(item => {
        const task = tasks.find(t => t.id === item.value);
        return {
          id: task.id,
          text: extractHeadline(task.result) || task.description
        };
      }));
    }
  }, [value, context, tasks]);

  const filteredTasks = useMemo(() => {
    if (!showDropdown || !Array.isArray(tasks)) return [];
    const searchTerm = inputValue.split('@').pop().toLowerCase();
    return tasks
      .filter(task => 
        task && 
        task.description && 
        typeof task.description === 'string' && 
        task.description.toLowerCase().includes(searchTerm)
      )
      .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
  }, [showDropdown, inputValue, tasks]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setShowDropdown(newValue.includes('@') && mentions.length < maxMentions);
    onChange({
      description: newValue,
      context: mentions.map(mention => ({ type: "taskId", value: mention.id }))
    });
  };

  const addMention = useCallback((task) => {
    if (mentions.length >= maxMentions) return;
    if (!task || !task.description) return;

    // Check for duplicate mention IDs
    const isDuplicate = mentions.some(mention => mention.id === task.id);
    if (isDuplicate) {
      toast({
        title: "Duplicate mention",
        description: "This task has already been mentioned.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const headline = extractHeadline(task.result);
    const newMention = { id: task.id, text: headline || task.description };
    const updatedMentions = [...mentions, newMention];
    setMentions(updatedMentions);
    setShowDropdown(false);

    // Remove the "@" and any text after it from the input value
    const newInputValue = inputValue.replace(/@[^@]*$/, '');
    setInputValue(newInputValue);

    onChange({
      description: newInputValue, // Use the cleaned input value
      context: updatedMentions.map(mention => ({ type: "taskId", value: mention.id }))
    });
  }, [mentions, maxMentions, onChange, inputValue, toast]);

  const removeMention = useCallback((mentionToRemove) => {
    const updatedMentions = mentions.filter(m => m.id !== mentionToRemove.id);
    setMentions(updatedMentions);
    onChange({
      description: inputValue, // Preserve the current description
      context: updatedMentions.map(mention => ({ type: "taskId", value: mention.id }))
    });
  }, [mentions, onChange, inputValue]);

  return (
    <Box position="relative">
      <Textarea
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        focusBorderColor="purple.100"
        borderColor="gray.200"
        _hover={{ borderColor: "gray.300" }}
        rows={4}  // You can adjust this value as needed
        resize="vertical"
      />
      {showDropdown && filteredTasks.length > 0 && (
        <Box position="absolute" zIndex={10} w="100%" mt={1} bg="white" borderRadius="md" boxShadow="md" border="1px" borderColor="gray.200">
          {filteredTasks.map(task => (
            <Box key={task.id} fontSize="md" fontWeight="semibold" p={2} _hover={{ bg: "pink.50" }} cursor="pointer" onClick={() => addMention(task)}>
              {extractHeadline(task.result)}
            </Box>
          ))}
        </Box>
      )}
      <Flex mt={2} flexWrap="wrap">
        {mentions.map(mention => (
          <Tag key={mention.id} size="md" borderRadius="md" variant="subtle" colorScheme="purple" mr={2} mb={2}>
            <TagLeftIcon boxSize="12px" as={FiFileText} />
            <TagLabel>
              <Flex alignItems="center" flexWrap="wrap" p={1}>
                {mention.text}
              </Flex>
            </TagLabel>
            <TagCloseButton onClick={() => removeMention(mention)} />
          </Tag>
        ))}
      </Flex>
      {mentions.length >= maxMentions && (
        <Text mt={2} fontSize="sm" color="red.500" fontWeight="semibold" >
          Maximum mentions reached ({maxMentions})
        </Text>
      )}
    </Box>
  );
};

export default TaskMentionSystem;