import React from 'react';

const GlowingGradientWrapper = ({ children, className = '' }) => {
  return (
    <div className={`relative overflow-hidden ${className}`}>
      <div className="absolute inset-0 bg-gradient-to-r from-blue-200 via-purple-200 to-pink-200 opacity-75 blur-xl animate-pulse"></div>
      <div className="absolute inset-0 bg-gradient-to-r from-blue-300 via-purple-300 to-pink-300 opacity-50 animate-pulse" style={{ animationDelay: '-2s' }}></div>
      <div className="relative z-10">{children}</div>
    </div>
  );
};

export default GlowingGradientWrapper;