import React from 'react';
import { Box, Text, HStack } from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons';

function calculateReadingTime(text) {
  const wordsPerMinute = 200;
  const wordCount = text.split(/\s+/).length;
  const readingTime = Math.ceil(wordCount / wordsPerMinute);
  return readingTime;
}

function ReadingTimeCapsule({ text }) {
  const readingTime = calculateReadingTime(text);

  return (
    <Box
      borderWidth="1px"
      borderColor="gray.600"
      borderRadius="full"
      px={2}
      py={1}
      bg="gray.700"
    >
      <HStack spacing={1}>
        <TimeIcon color="gray.400" boxSize={3} />
        <Text fontSize="xs" color="gray.400">
          {readingTime <= 1 ? "<1 min. read" : `${readingTime} min. read`}
        </Text>
      </HStack>
    </Box>
  );
}

export default ReadingTimeCapsule;