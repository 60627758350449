import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      500: '#38B2AC', // Teal color
      600: '#319795', // Darker teal for hover
    },
  },
  components: {
    Button: {
      baseStyle: {
        colorScheme: 'brand',
        _hover: {
          bg: 'brand.600', // Use the darker teal on hover
        },
      },
    },
  },
});

// export default theme;