import React from 'react';
import { Box, Button } from '@chakra-ui/react';

export const SelectionToolbar = ({ position, onExpand }) => {
  return (
    <Box
      position="absolute"
      top={`${position.top}px`}
      left={`${position.left}px`}
      zIndex="tooltip"
      bg="white"
      boxShadow="md"
      borderRadius="md"
      p={2}
    >
      <Button size="sm" onClick={onExpand}>
        Quick Expand
      </Button>
    </Box>
  );
};