import React, { useState } from 'react';
import { Box, Flex, Text, Icon, IconButton, VStack } from '@chakra-ui/react';
import { FaProjectDiagram, FaTrash } from 'react-icons/fa';

const WorkflowCard = ({ workflow, onClick, onDelete }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      p={4}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="sm"
      _hover={{ boxShadow: "md", transform: "translateY(-2px)" }}
      cursor="pointer"
      onClick={onClick}
      transition="all 0.2s"
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Flex align="flex-start">
        <Icon as={FaProjectDiagram} boxSize={6} color="blue.500" mr={3} mt={1} />
        <VStack align="start" spacing={1}>
          <Text fontWeight="bold" fontSize="lg" color="gray.800">{workflow.name}</Text>
          <Text fontSize="sm" fontWeight="medium" color="gray.600">
            {Object.keys(workflow.graph.steps).length} steps
          </Text>
          <Text fontSize="xs" color="gray.500">
            Created: {new Date(workflow.created_at).toLocaleDateString()}
          </Text>
        </VStack>
      </Flex>
      {isHovered && (
        <IconButton
          icon={<FaTrash />}
          size="sm"
          colorScheme="red"
          variant="ghost"
          position="absolute"
          bottom={2}
          right={2}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(workflow.id);
          }}
          aria-label="Delete workflow"
        />
      )}
    </Box>
  );
};

export default WorkflowCard;