import React from 'react';
import { Handle, Position, NodeToolbar } from '@xyflow/react';
import { Text, useColorModeValue, Box, Button, IconButton } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

const SourceNode = ({ data, id }) => {
  const textColor = useColorModeValue('gray.800', 'white');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');

  return (
    <>
      <NodeToolbar
        isVisible={data.toolbarVisible}
        position={Position.Top}
        onMouseEnter={data.onToolbarMouseEnter}
        onMouseLeave={() => data.onToolbarMouseLeave(id)}
      >
        <Button size="sm" leftIcon={<EditIcon />} mr={2} onClick={(e) => {
          e.stopPropagation(); // Prevent the click from bubbling up to the node
          data.onEdit(id);
        }}>
          Edit
        </Button>
        <IconButton
          size="sm"
          icon={<DeleteIcon />}
          colorScheme="red"
          aria-label="Delete node"
          onClick={(e) => {
            e.stopPropagation(); // Prevent the click from bubbling up to the node
            data.onRemove(id);
          }}
        />
      </NodeToolbar>
      <Box 
        bg="#ECE2D0" 
        p="10px" 
        borderRadius="8px"
        cursor="pointer" // Add this line to show it's clickable
      >
        <Text as="h4" fontSize="lg" color={textColor} fontWeight="bold" textAlign="left">{data.label}</Text>
        <Text fontSize="0.8em" color={subTextColor} textAlign="left">{data.agentName}</Text>
        {data.description && (
          <Text as="p" color={textColor} mt={2} noOfLines={2} textAlign="left">
            {data.description.length > 100
              ? data.description.slice(0, 100) + '...'
              : data.description}
          </Text>
        )}
        <Handle
          type="source"
          position={Position.Right}
          style={{ width: '12px', height: '12px' }}
        />
      </Box>
    </>
  );
};

export default SourceNode;