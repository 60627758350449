import React, { useState } from 'react';
import {
  Button,
  VStack,
  Text,
  Progress,
  Box,
  useToast,
  Input,
} from '@chakra-ui/react';
import * as fal from '@fal-ai/serverless-client';
import { FaSparkles } from 'react-icons/fa';

const runwayGen3Turbo = "fal-ai/runway-gen3/turbo/image-to-video";
const stableVideo = "fal-ai/stable-video";

const ImageToVideoConverter = ({ imageUrl, onVideoGenerated, prompt, setPrompt, onGenerateStart }) => {
  const [isConverting, setIsConverting] = useState(false);
  const toast = useToast();

  const handleGenerateVideo = async () => {
    onGenerateStart(); // Call this when starting video generation
    if (!prompt.trim()) {
      toast({
        title: 'Prompt Required',
        description: 'Please enter a prompt to convert the image to video.',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    setIsConverting(true);

    try {
      const result = await fal.subscribe(runwayGen3Turbo, {
        input: {
          image_url: imageUrl,
          prompt: prompt,
        },
        logs: false,
      });

      if (result.video) {
        console.log("Video url", result.video.url);
        onVideoGenerated(result.video.url);
        toast({
          title: 'Video generated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('No video generated');
      }
    } catch (error) {
      console.error('Error converting image to video:', error);
      toast({
        title: 'Error',
        description: 'Failed to convert image to video. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsConverting(false);
    }
  };

  return (
    <Button
      onClick={handleGenerateVideo}
      isLoading={isConverting}
      loadingText="Converting..."
      bg="lavender"
      color="purple.700"
      _hover={{ bg: "purple.100" }}
      width="100%"
      isDisabled={!prompt.trim()}
    >
      Convert
    </Button>
  );
};

export default ImageToVideoConverter;