import React, { useState, useRef, useEffect, Fragment, useCallback } from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { getCodeString } from 'rehype-rewrite';
import mermaid from 'mermaid';
import { Box, useColorModeValue, VStack, SimpleGrid, useToast, Image, Popover, PopoverTrigger, PopoverContent, PopoverBody, Button, Input, AspectRatio } from '@chakra-ui/react';
import { SelectionToolbar } from './SelectionToolbar';
import { DiffModal } from './DiffModal';
import { expandText, updateTask } from '../utils/api';
import FalImageGenerator from './FalImageGenerator';
import EditableImage from './EditableImage';
import * as fal from '@fal-ai/serverless-client';

// Initialize the fal client with your API key
fal.config({
  credentials: process.env.REACT_APP_FAL_AI_API_KEY,
});

const randomid = () => parseInt(String(Math.random() * 1e15), 10).toString(36);

// CodeRenderer remains the same
const CodeRenderer = ({ inline, children = [], className, ...props }) => {
  const demoid = useRef(`dome${randomid()}`);
  const [container, setContainer] = useState(null);
  const isMermaid = className && /^language-mermaid/.test(className.toLocaleLowerCase());
  const code = props.node && props.node.children ? getCodeString(props.node.children) : children[0] || '';
  
  const reRender = async () => {
    if (container && isMermaid) {
      try {
        const str = await mermaid.render(demoid.current, code);
        container.innerHTML = str.svg;
      } catch (error) {
        container.innerHTML = error;
      }
    }
  }
  
  useEffect(() => {
    reRender()
  }, [container, isMermaid, code, demoid]);
  
  const refElement = useCallback((node) => {
    if (node !== null) {
      setContainer(node);
    }
  }, []);
  
  if (isMermaid) {
    return (
      <Fragment>
        <code id={demoid.current} style={{ display: "none" }} />
        <code ref={refElement} data-name="mermaid" />
      </Fragment>
    );
  }
  return <code>{children}</code>;
};

// CustomVideoComponent handles both direct src and nested source elements
const CustomVideoComponent = ({ children, ...props }) => {
  let src = props.src;

  // If src is not directly available, extract it from child <source> elements
  if (!src && children) {
    const sourceElement = React.Children.toArray(children).find(
      (child) => child.props && child.props.src
    );
    if (sourceElement) {
      src = sourceElement.props.src;
    }
  }

  // If src is still not found, log a warning and don't render the video
  if (!src) {
    console.warn('CustomVideoComponent: Video source is empty or undefined.');
    return null;
  }

  return (
    <AspectRatio maxW="640px" ratio={4 / 3} margin="0 auto" borderRadius="md">
      <video
        controls
        autoPlay
        muted
        playsInline
        loop
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '8px',
        }}
        {...props}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </AspectRatio>
  );
};

const MarkdownPreviewComponent = ({ initialContent, taskId, onContentUpdate, editable = false }) => {
  const [content, setContent] = useState(initialContent);
  const [selectedText, setSelectedText] = useState('');
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
  const [showToolbar, setShowToolbar] = useState(false);
  const [showDiffModal, setShowDiffModal] = useState(false);
  const [expandedText, setExpandedText] = useState('');
  const codeTheme = useColorModeValue('light', 'dark');
  const containerRef = useRef(null);
  const toast = useToast();

  const [generatedImages, setGeneratedImages] = useState([]);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prompt, setPrompt] = useState('');

  const handleTextSelection = useCallback(() => {
    if (!editable) return; // Don't show toolbar if not editable

    const selection = window.getSelection();
    const text = selection.toString().trim();

    if (text) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      setSelectedText(text);
      setToolbarPosition({
        top: rect.bottom - containerRect.top,
        left: rect.left - containerRect.left,
      });
      setShowToolbar(true);
    } else {
      setShowToolbar(false);
    }
  }, [editable]); // Add editable to the dependency array

  const handleExpand = async () => {
    try {
      const expandedText = await expandText(taskId, selectedText);
      setExpandedText(expandedText);
      setShowDiffModal(true);
      setShowToolbar(false);
    } catch (error) {
      console.error('Failed to expand text:', error);
      toast({
        title: "Error",
        description: "Failed to expand text",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAcceptChange = useCallback(async () => {
    if (selectedText && expandedText) {
      const newContent = content.replace(selectedText, expandedText);
      
      try {
        const updatedTask = await updateTask(taskId, { result: newContent });
        setContent(newContent);
        onContentUpdate(updatedTask); // Call this function to update the parent component
        toast({
          title: "Result updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Failed to update task:', error);
        toast({
          title: "Error",
          description: "Failed to update task",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setShowDiffModal(false);
    setShowToolbar(false);
  }, [content, selectedText, expandedText, taskId, toast, onContentUpdate]);

  const handleDeclineChange = () => {
    setShowDiffModal(false);
    setShowToolbar(false); // Hide the toolbar
  };

  // New function to remove top-level markdown code block if present
  const extractMarkdownCodeBlock = (text) => {
    if (!text) return ''; // Handle undefined or null content
    const codeBlockRegex = /^```markdown\s*([\s\S]*?)\s*```/;
    const match = text.match(codeBlockRegex);
    return match ? match[1].trim() : text;
  };

  const handleImageGeneration = async (images) => {
    setGeneratedImages(images);
    setIsGeneratingImages(false);
  };

  const handleImageSelect = useCallback(async (oldSrc, newSrc) => {
    const newContent = content.replace(oldSrc, newSrc);
    
    try {
      const updatedTask = await updateTask(taskId, { result: newContent });
      setContent(newContent);
      onContentUpdate(updatedTask); // Call this function to update the parent component
      toast({
        title: "Image updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Failed to update task:', error);
      toast({
        title: "Error",
        description: "Failed to update image in task",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [content, taskId, toast, onContentUpdate]);

  const validateImagesNotNSFW = (data) => {
    return data.has_nsfw_concepts.every(concept => concept === false);
  };

  const handleGenerateWithAI = async (src) => {
    setIsLoading(true);
    try {
      const result = await fal.run('fal-ai/flux/schnell', {
        input: {
          prompt: prompt,
          image_url: src,
          num_images: 4,
        },
      });

      if (validateImagesNotNSFW(result)) {
        setGeneratedImages(result.images);
      } else {
        toast({
          title: 'Hmm..',
          description: 'Detected NSFW content 👀',
          status: 'warn',
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error generating images:', error);
      toast({
        title: 'Error',
        description: 'Failed to generate images',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadImage = (src) => {
    // Implement the logic to upload a new image
    console.log("Upload new image for:", src);
    // You can implement an image upload functionality here
  };

  const handleVideoGenerated = useCallback(async (videoUrl, imageSrc) => {
    // Regex to match the entire line containing the image, regardless of alt text
    const imageRegex = new RegExp(`^.*!\\[.*?\\]\\(${imageSrc.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\).*$`, 'm');
    
    const videoHtml = `<video width="100%" controls><source src="${videoUrl}" type="video/mp4">Your browser does not support the video tag.</video>`;

    const newContent = content.replace(imageRegex, videoHtml);
    try {
      const updatedTask = await updateTask(taskId, { result: newContent });
      setContent(newContent);
      onContentUpdate(updatedTask);
      toast({
        title: "Image converted to video",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to convert image to video",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [content, taskId, toast, onContentUpdate]);

  const CustomImageComponent = ({ src, alt, ...props }) => {
    return (
      <EditableImage
        src={src}
        alt={alt}
        onUploadImage={handleUploadImage}
        handleGenerateWithAI={handleGenerateWithAI}
        onImageSelect={(newSrc) => handleImageSelect(src, newSrc)}
        onVideoGenerated={handleVideoGenerated}
      />
    );
  };

  // Process the content before passing it to MarkdownPreview
  const processedContent = extractMarkdownCodeBlock(content);

  if (!processedContent) {
    return null;
  }

  return (
    <Box ref={containerRef} position="relative" onMouseUp={handleTextSelection}>
      <MarkdownPreview
        source={processedContent}
        style={{ padding: 10, backgroundColor: 'transparent' }}
        rehypeRewrite={(node, index, parent) => {
          if (node.tagName === "a" && parent && /^h(1|2|3|4|5|6)/.test(parent.tagName)) {
            parent.children = parent.children.slice(1)
          }
        }}
        components={{
          code: CodeRenderer,
          ul: ({ children, ...props }) => (
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginBottom: '10px' }} {...props}>
              {children}
            </ul>
          ),
          ol: ({ children, ...props }) => (
            <ol style={{ listStyleType: 'decimal', paddingLeft: '20px', marginBottom: '10px' }} {...props}>
              {children}
            </ol>
          ),
          li: ({ children, ...props }) => (
            <li style={{ marginBottom: '5px' }} {...props}>
              {children}
            </li>
          ),
          table: ({ children, ...props }) => (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <table style={{ borderCollapse: 'collapse', margin: '15px 0' }} {...props}>
                {children}
              </table>
            </div>
          ),
          img: CustomImageComponent,
          video: CustomVideoComponent,
        }}
        wrapperElement={{
          "data-color-mode": codeTheme,
          style: { backgroundColor: 'transparent' }
        }}
      />
      {editable && showToolbar && (
        <SelectionToolbar
          position={toolbarPosition}
          onExpand={handleExpand}
        />
      )}
      {editable && showDiffModal && (
        <DiffModal
          originalText={selectedText}
          expandedText={expandedText}
          onAccept={handleAcceptChange}
          onDecline={handleDeclineChange}
        />
      )}
    </Box>
  );
}

export default MarkdownPreviewComponent;