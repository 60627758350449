import React, { useState, useRef } from 'react';
import {
  Box,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Input,
  VStack,
  SimpleGrid,
  useToast,
  useDisclosure,
  Text,
  AspectRatio,
} from '@chakra-ui/react';
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import * as fal from '@fal-ai/serverless-client';
import ImageToVideoConverter from './ImageToVideoConverter';

// Initialize the fal client with your API key
fal.config({
  credentials: process.env.REACT_APP_FAL_AI_API_KEY,
});

const EditableImage = ({ src, alt, onUploadImage, onImageSelect, onVideoGenerated }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const editButtonRef = useRef(null);
  const [mode, setMode] = useState('image'); // 'image' or 'video'
  const videoRef = useRef(null);
  const [pendingVideoUrl, setPendingVideoUrl] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isGeneratingVideo, setIsGeneratingVideo] = useState(false);

  const falModel = process.env.REACT_APP_FAL_AI_MODEL || 'fal-ai/flux/schnell';

  const validateImagesNotNSFW = (data) => {
    return data.has_nsfw_concepts.every(concept => concept === false);
  };

  const handleGenerateWithAI = async () => {
    if (!prompt.trim()) {
      toast({
        title: 'Prompt Required',
        description: 'Please enter a prompt to generate images.',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const result = await fal.run(falModel, {
        input: {
          prompt: prompt,
          image_url: src,
          num_images: 4,
        },
      });

      if (validateImagesNotNSFW(result)) {
        setGeneratedImages(result.images);
      } else {
        toast({
          title: 'Content Warning',
          description: 'Detected NSFW content 👀',
          status: 'warning',
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error generating images:', error);
      toast({
        title: 'Generation Error',
        description: 'Failed to generate images. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageSelect = (imageUrl) => {
    onImageSelect(imageUrl);
    onClose();
  };

  const handleMenuItemClick = (action) => {
    if (action === 'generate') {
      setMode('image');
      onOpen();
    } else if (action === 'video') {
      setMode('video');
      onOpen();
    }
  };

  const handlePopoverClose = () => {
    onClose();
    setPrompt('');
    setGeneratedImages([]);
  };

  const handleVideoGenerated = (videoUrl) => {
    setPendingVideoUrl(videoUrl);
    setIsGeneratingVideo(false);
  };

  const handleGenerateVideo = () => {
    setIsGeneratingVideo(true);
  };

  const handleUseVideo = () => {
    onVideoGenerated(pendingVideoUrl, src);
    setPendingVideoUrl('');
    onClose();
  };

  const handleVideoEnded = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };

  return (
    <Box
      maxW="600px"
      maxH="450px"
      w="100%"
      h="auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
      margin="0 auto"
      mb={4}
      bg="white"
      borderRadius="lg"
      overflow="visible"
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Image
        src={src}
        alt={alt}
        maxW="100%"
        maxH="100%"
        objectFit="contain"
        borderRadius="lg"
        transition="transform 0.3s ease-in-out"
        transform={isHovered ? "scale(1.05)" : "scale(1)"}
      />
      {isHovered && (
        <Box
          position="absolute"
          top="10px"
          right="10px"
          zIndex="tooltip"
        >
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              bg="purple.100"
              color="purple.700"
              size="sm"
              borderRadius="full"
              _hover={{ bg: "purple.200" }}
              _active={{ bg: "purple.300" }}
              ref={editButtonRef}
            >
              Edit
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => handleMenuItemClick('generate')}>Generate with AI</MenuItem>
              <MenuItem onClick={onUploadImage}>Upload your own</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('video')}>Convert to Video</MenuItem>
            </MenuList>
          </Menu>

          <Popover
            isOpen={isOpen}
            onClose={handlePopoverClose}
            placement="bottom-end"
            closeOnBlur={false}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ]}
          >
            <PopoverTrigger>
              <Box ref={editButtonRef} position="absolute" top="0" right="0" />
            </PopoverTrigger>
            <PopoverContent minW="300px" px={4} pb={4}> {/* Added pb={4} for bottom padding */}
              <PopoverBody py={4}> {/* Added py={4} for vertical padding */}
                <VStack spacing={4}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <Text as="h3" fontWeight="bold">
                      {mode === 'image' ? 'Generate Image' : 'Convert to Video'}
                    </Text>
                    <Button
                      size="xs"
                      colorScheme="gray"
                      variant="ghost"
                      onClick={handlePopoverClose}
                      borderRadius="full"
                    >
                      <CloseIcon />
                    </Button>
                  </Box>
                  {mode === 'video' && (
                    <Text fontSize="md" fontWeight="semibold" color="gray.600">
                      Enter a prompt for the video you want to generate.
                    </Text>
                  )}
                  <Input
                    placeholder={mode === 'image' ? "A dog running in a park" : "A dog running in a park"}
                    value={prompt}
                    fontSize="sm"
                    fontWeight="semibold"
                    onChange={(e) => setPrompt(e.target.value)}
                    autoFocus
                    focusBorderColor="gray.300"
                    border="1px solid"
                    borderColor="gray.300"
                  />
                  {mode === 'image' ? (
                    <Button
                      onClick={handleGenerateWithAI}
                      isLoading={isLoading}
                      loadingText="Generating..."
                      bg="lavender"
                      color="purple.700"
                      _hover={{ bg: "purple.100" }}
                      width="100%"
                      isDisabled={!prompt.trim()}
                    >
                      Generate
                    </Button>
                  ) : (
                    <>
                      {!isGeneratingVideo && (
                        <ImageToVideoConverter
                          imageUrl={src}
                          onVideoGenerated={handleVideoGenerated}
                          prompt={prompt}
                          setPrompt={setPrompt}
                          onGenerateStart={handleGenerateVideo}
                        />
                      )}
                      {isGeneratingVideo && !pendingVideoUrl && (
                          <Button
                            isLoading={true}
                            loadingText="Generating video..."
                            colorScheme="purple"
                            width="100%"
                            isDisabled={true}
                          >
                            Generate Video
                          </Button>
                      )}
                      {pendingVideoUrl && (
                        <>
                          <Box width="100%" borderRadius="md" overflow="hidden" mt={2}>
                          <AspectRatio maxW="640px" ratio={4 / 3} margin="0 auto" borderRadius="md">
                            <video
                              ref={videoRef}
                              src={pendingVideoUrl}
                              autoPlay
                              loop
                              muted
                              playsInline
                              width="100%"
                              height="auto"
                              style={{
                                maxHeight: '200px',
                                objectFit: 'contain',
                                borderRadius: '8px',
                              }}
                              onEnded={handleVideoEnded}
                            >
                              Your browser does not support the video tag.
                            </video>
                            </AspectRatio>
                          </Box>
                          <Button
                            onClick={handleUseVideo}
                            bg="green.100"
                            color="green.700"
                            _hover={{ bg: "green.200" }}
                            width="100%"
                            size="md"
                            mt={-2}
                          >
                            Use This Video
                          </Button>
                        </>
                      )}
                    </>
                  )}
                  {mode === 'video' && !pendingVideoUrl  && isGeneratingVideo && (
                    <Text fontSize="sm" color="gray.600" textAlign="center">
                      The video generation process may take up to a minute
                    </Text>
                  )}
                  {mode === 'image' && generatedImages.length > 0 && (
                    <SimpleGrid columns={2} spacing={4} width="100%">
                      {generatedImages.map((img, index) => (
                        <Box
                          key={index}
                          position="relative"
                          width="100%"
                          paddingBottom="100%"
                          overflow="visible"
                        >
                          <Image
                            src={img.url}
                            alt={`Generated image ${index + 1}`}
                            cursor="pointer"
                            borderRadius="md"
                            boxShadow="sm"
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            objectFit="cover"
                            transition="all 0.3s ease-in-out"
                            _hover={{
                              transform: "scale(1.2)",
                              boxShadow: 'xl',
                              zIndex: 2
                            }}
                            onClick={() => handleImageSelect(img.url)}
                          />
                        </Box>
                      ))}
                    </SimpleGrid>
                  )}
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      )}
    </Box>
  );
};

export default EditableImage;