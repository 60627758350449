import React, { useState } from 'react';
import {
  Box, VStack, Text, Heading, useColorModeValue, AvatarGroup, Tooltip,
  Avatar, HStack, useToast, Button
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { createAvatar } from '@dicebear/core';
import { thumbs } from '@dicebear/collection';
import { StarIcon, CheckIcon } from '@chakra-ui/icons';
import TaskCarousel from './TaskCarousel';
import CloneCapsule from './CloneCapsule';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import API_BASE_URL from '../config';
import { useFollowCrew } from '../hooks/useFollowCrew';

function PublicCrewCard({ crew, followers, featuredTasks }) {
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const navigate = useNavigate();
  const { user } = useAuth();
  const toast = useToast();
  const { isFollowing, followersCount, handleFollowToggle } = useFollowCrew(
    followers.includes(user?.uid),
    followers.length,
    crew.id
  );
  const [isSaved, setIsSaved] = useState(false);

  const TooltipAvatar = (props) => (
    <Tooltip label={props.name}>
      <Avatar {...props} />
    </Tooltip>
  );

  const getAvatarSvg = (seed) => {
    const avatar = createAvatar(thumbs, {
      seed,
      backgroundColor: ['b6e3f4', 'c0aede', 'd1d4f9'],
      radius: 50,
    });
    return avatar.toDataUri();
  };

  const handleCardClick = (e) => {
    // Check if the click originated from the carousel
    if (!e.target.closest('.task-carousel')) {
      navigate(`/public-crews/${crew.id}`, { state: { crew, featuredTasks } });
    }
  };

  const handleTaskSelect = (taskId, e) => {
    e.stopPropagation(); // Prevent the card click event from firing
    navigate(`/sharedtask/${taskId}`);
  };

  const handleClone = async (e) => {
    e.stopPropagation(); // Prevent the card click event from firing

    if (!user) {
      toast({
        title: "Please log in",
        description: "You need to be logged in to copy this team.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const idToken = await user.getIdToken();
      await axios.post(`${API_BASE_URL}/public-crews/${crew.id}/copy`, {}, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setIsSaved(true);
      toast({
        title: "Team copied",
        description: "This team has been copied to your library.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error copying team:', error);
      toast({
        title: "Error",
        description: "Failed to copy the team. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="xl"
      borderColor={borderColor}
      p={4}
      boxShadow="md"
      transition="all 0.3s"
      _hover={{ boxShadow: 'xl', transform: 'translateY(-2px)' }}
      bg={bgColor}
      onClick={handleCardClick}
    >
      <VStack align="stretch" spacing={4} pt={1} pl={2}>
        <Heading as="h4" size="md" color={textColor}>
          {crew.name}
        </Heading>
        <Text fontSize="sm" color="gray.500" mt={-2} mb={2}>
          {crew.description}
        </Text>
        <Box>
          <Text fontWeight="semibold" mb={0} color={textColor}>
            Minions
          </Text>
          <AvatarGroup size="md" max={3}>
            {crew.agents.map((agent) => (
              <TooltipAvatar key={agent.id} name={agent.role} src={getAvatarSvg(agent.id)} />
            ))}
          </AvatarGroup>
        </Box>
        <HStack justifyContent="flex-start" mt={4} spacing={4}>
          <CloneCapsule 
            numClones={crew.num_clones} 
            onClone={handleClone}
            isSaved={isSaved}
          />
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleFollowToggle();
            }}
            size="sm"
            leftIcon={isFollowing ? <CheckIcon /> : <StarIcon />}
            colorScheme={isFollowing ? "green" : "blue"}
            variant="outline"
            borderRadius="full"
          >
            {isFollowing ? "Following" : "Follow"} • {followersCount}
          </Button>
        </HStack>
        {featuredTasks.length > 0 && (
          <Box className="task-carousel">
            <Text fontWeight="bold" mb={2} color={textColor}>
              Featured Tasks
            </Text>
            <TaskCarousel tasks={featuredTasks} onTaskSelect={handleTaskSelect} />
          </Box>
        )}

      </VStack>
    </Box>
  );
}

export default PublicCrewCard;