import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
  Text,
  Flex,
  useDisclosure,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import axios from 'axios';
import API_BASE_URL from '../config';
import AgentLibrary from './AgentLibrary';
import AgentCard from './AgentCard';
import AgentFormModal from './AgentFormModal';
import { MdSearch } from 'react-icons/md';
import CondensedMinionCard from './CondensedMinionCard';
import { getAuth } from 'firebase/auth';
import { useCrews } from '../contexts/CrewsContext'; // Import the useCrews hook

function CrewForm() {
  const [name, setName] = useState('');
  const [agents, setAgents] = useState([]);
  const [editingAgent, setEditingAgent] = useState(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isLibraryOpen, onOpen: onLibraryOpen, onClose: onLibraryClose } = useDisclosure();
  const { crews, fetchCrews } = useCrews(); // Use the crews and fetchCrews from the context

  const handleSelectPresetAgent = (agent) => {
    setAgents([...agents, { ...agent, isPreset: true }]);
  };

  const handleEditAgent = (agent) => {
    setEditingAgent(agent);
    onOpen();
  };

  const handleCreateCustomAgent = () => {
    setEditingAgent(null);
    onOpen();
  };

  const handleSaveAgent = (updatedAgent) => {
    if (editingAgent) {
      setAgents(agents.map(a => 
        a.id === editingAgent.id ? { 
          ...updatedAgent, 
          id: editingAgent.id, 
          isPreset: editingAgent.isPreset,
          web_search_enabled: updatedAgent.web_search_enabled,
          code_execution_enabled: updatedAgent.code_execution_enabled
        } : a
      ));
    } else {
      setAgents([...agents, { 
        ...updatedAgent, 
        id: Date.now().toString(),
        web_search_enabled: updatedAgent.web_search_enabled,
        code_execution_enabled: updatedAgent.code_execution_enabled
      }]);
    }
    setEditingAgent(null);
  };

  const handleRemoveAgent = (agentToRemove) => {
    setAgents(agents.filter(agent => agent.id !== agentToRemove.id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }

      const idToken = await user.getIdToken();

      // Create the crew
      const crewResponse = await axios.post(`${API_BASE_URL}/crews`, {
        name,
        process: 'hierarchical'
      }, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      const crewId = crewResponse.data.id;

      // Add agents to the crew
      for (const agent of agents) {
        await axios.post(`${API_BASE_URL}/crews/${crewId}/agents`, {
          role: agent.role,
          goal: agent.goal,
          backstory: agent.backstory,
          web_search_enabled: agent.web_search_enabled,
          code_execution_enabled: agent.code_execution_enabled,
          tools: agent.tools
        }, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
      }
      
      toast({
        title: 'Crew created.',
        description: "We've created your crew and added the agents.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setName('');
      setAgents([]);
      
      // Refetch crews after successful creation
      await fetchCrews();
    } catch (error) {
      console.error('Error creating crew:', error);
      toast({
        title: 'Error',
        description: 'Failed to create crew or add agents.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const isFormValid = name.trim() !== '' && agents.length > 0;

  return (
    <Flex direction={["column", "column", "row"]} gap={8}>
      <Box flex={2} minWidth="300px">
        <Heading size="lg" mb={4} color="gray.600">Create a new team</Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel color="gray.600" fontWeight="semibold">Team Name</FormLabel>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your team's name"
                color="gray.600"
                borderRadius="full"
              />
            </FormControl>
            <Box>
              {agents.length > 0 && <Text mb={2} fontWeight="semibold" color="gray.600">Minions</Text>}
              <VStack spacing={2} align="stretch" mb={4}>
                {agents.map((agent) => (
                  <CondensedMinionCard
                    key={agent.id}
                    agent={agent}
                    onRemove={handleRemoveAgent}
                    onEdit={handleEditAgent}
                  />
                ))}
              </VStack>
              <Flex justifyContent="space-between" mt={4}>
                <Button
                  leftIcon={<MdSearch />}
                  onClick={onLibraryOpen}
                  bg="purple.100"
                  color="purple.800"
                  _hover={{ bg: "purple.200" }}
                  variant="solid"
                  borderRadius="full"
                >
                  Explore library
                </Button>
                <AddCustomMinionButton 
                  onClick={handleCreateCustomAgent} 
                  colorScheme="teal"
                />
              </Flex>
            </Box>
            <Button 
              type="submit" 
              colorScheme="teal" 
              mt={6} 
              width="100%" 
              variant="solid"
              isDisabled={!isFormValid}
              borderRadius="full"
            >
              Create Team
            </Button>
          </VStack>
        </form>
      </Box>
      <Modal isOpen={isLibraryOpen} onClose={onLibraryClose} size="6xl">
        <ModalOverlay />
        <ModalContent borderRadius="3xl" overflow="hidden">
          <ModalCloseButton />
          <ModalBody pt={10} pb={20}>
            <AgentLibrary
              onSelectAgent={(agent) => {
                handleSelectPresetAgent(agent);
                onLibraryClose();
              }}
              selectedAgentIds={agents.map(agent => agent.id)}
              displayGrid={true}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      {isOpen && (
        <AgentFormModal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setEditingAgent(null);
          }}
          onSave={handleSaveAgent}
          initialAgent={editingAgent} // Pass the editing agent to populate fields
        />
      )}
    </Flex>
  );

  function AddCustomMinionButton() {
    return (
      <Button leftIcon={<AddIcon />} onClick={handleCreateCustomAgent} colorScheme="teal" variant="outline" borderRadius="full">
        Custom Minion
      </Button>
    );
  }
}

export default CrewForm;