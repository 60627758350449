import React from 'react';
import { Box, Text, Button, Flex, useColorModeValue, IconButton, Tooltip } from '@chakra-ui/react';
import { getStatusBadge } from './CrewDetails';
import { MdPlayArrow, MdDelete } from 'react-icons/md';

function TaskCard({ task, onClick, executeTask, deleteTask }) {
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const deleteIconBg = useColorModeValue('red.100', 'red.900');
  const deleteIconColor = useColorModeValue('red.500', 'red.200');

  return (
    <Box 
      position="relative"
      borderWidth="1px"
      borderRadius="lg"
      borderColor={borderColor}
      bg={bgColor}
      p={4}
      boxShadow="sm"
      transition="all 0.2s"
      _hover={{ 
        boxShadow: 'md',
        '& > .delete-button': { opacity: 1 }
      }}
      width="100%"
      cursor="pointer"
      onClick={() => onClick(task)}
    >
      <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
        <Box flex="1" mr={4}>
          <Text fontSize="sm" color="gray.500" mb={3}>
            <Text as="strong">Task Description</Text>
            <Text>{task.description}</Text>
          </Text>
          <Text fontSize="sm" color="gray.500" mb={3}>
            <Text as="strong">Expected Output</Text>
            <Text>{task.expected_output}</Text>
          </Text>
        </Box>
        <Flex direction="column" alignItems="flex-end">
          {getStatusBadge(task.status)}
          {task.status === 0 && (
            <Button
              size="xs"
              bg="rgba(230, 180, 230, 0.6)"
              color="purple.800"
              mt={2}
              leftIcon={<MdPlayArrow />}
              onClick={(e) => { e.stopPropagation(); executeTask(task.id); }}
              borderRadius="full"
              iconSpacing={1}
              paddingRight={3}
              _hover={{
                bg: "rgba(230, 180, 230, 0.8)",
              }}
              transition="background-color 0.2s"
            >
              Start
            </Button>
          )}
        </Flex>
      </Flex>
      <Tooltip label="Delete this task" hasArrow>
        <IconButton
          className="delete-button"
          icon={<MdDelete />}
          size="sm"
          position="absolute"
          bottom="2"
          right="2"
          bg={deleteIconBg}
          color={deleteIconColor}
          opacity="0"
          _hover={{
            bg: 'red.100',
            color: 'red.700'
          }}
          onClick={(e) => {
            e.stopPropagation();
            deleteTask(task.id);
          }}
          aria-label="Delete task"
          borderRadius="full"
          transition="all 0.2s"
        />
      </Tooltip>
    </Box>
  );
}

export default TaskCard;