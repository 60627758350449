import React, { useState } from 'react';
import { Box, Button, Image, SimpleGrid, Text, VStack, useToast } from '@chakra-ui/react';
import * as fal from '@fal-ai/serverless-client';

// Initialize the fal client with your API key
fal.config({
  credentials: process.env.REACT_APP_FAL_AI_API_KEY,
});

const FalImageGenerator = ({ prompt, imageUrl, onImageGenerated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

    // create function validateImagesNotNSFW
    const validateImagesNotNSFW = (data) => {
      // Check if all values in has_nsfw_concepts array are false
      return data.has_nsfw_concepts.every(concept => concept === false);
    };

  const generateImages = async () => {
    setIsLoading(true);
    try {
      const result = await fal.run('fal-ai/flux/schnell', {
        input: {
          prompt: prompt,
          image_url: imageUrl,
          num_images: 4,
        },
      });

      if (validateImagesNotNSFW(result)) {
        onImageGenerated(result.images);
      } else {
        toast({
          title: 'Hmm..',
          description: 'Detected NSFW content 👀',
          status: 'warn',
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error generating images:', error);
      toast({
        title: 'Error',
        description: 'Failed to generate images',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      onClick={generateImages}
      isLoading={isLoading}
      loadingText="Generating..."
      colorScheme="purple"
      size="md"
      borderRadius="full"
      px={6}
      fontWeight="bold"
    >
      Generate Images
    </Button>
  );
};

export default FalImageGenerator;