import React from 'react';
import { Heading } from '@chakra-ui/react';

const MyminionsLogo = ({ size = "lg", className = "" }) => {
  return (
    <Heading
      as="h1"
      size={size}
      fontFamily="'Roboto Mono', monospace"
      letterSpacing="0.1em"
      bgGradient="linear(to-r, purple.400, pink.500, red.500)"
      bgClip="text"
      className={className}
    >
      myminions
    </Heading>
  );
};

export default MyminionsLogo;
