import React, { useState, useRef } from 'react';
import { Box, Image, Text, VStack, Skeleton, IconButton, Tooltip, HStack, useToast, useDisclosure } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';

function ImageCard({ prompt, imageUrl, onImageLoad }) {  // Add onImageLoad prop
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'generated-image.png';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
    if (onImageLoad) {
      onImageLoad();  // Call the parent's onImageLoad function
    }
  };

  return (
    <Box 
      borderWidth={1} 
      borderRadius="lg" 
      overflow="hidden" 
      boxShadow="md" 
      position="relative"
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
      width="100%"
      maxWidth="800px"
    >
      <Skeleton isLoaded={imageLoaded} height="auto" width="100%">
        <Image 
          ref={imageRef}
          src={imageUrl} 
          alt={prompt} 
          objectFit="contain"
          width="100%" 
          height="auto"
          maxHeight="600px"
          onLoad={handleImageLoad}  // Use the new handler
          display={imageLoaded ? 'block' : 'none'}
        />
      </Skeleton>
      
      {isOpen && (
        <HStack 
          position="absolute" 
          top={2} 
          right={2} 
          spacing={2}
          bg="rgba(0, 0, 0, 0.6)"
          borderRadius="md"
          p={1}
        >
          <Tooltip label="Download Image">
            <IconButton
              icon={<DownloadIcon />}
              onClick={handleDownload}
              aria-label="Download Image"
              size="sm"
              colorScheme="whiteAlpha"
              variant="ghost"
            />
          </Tooltip>
        </HStack>
      )}
      
      <VStack p={4} align="start" bg="gray.50">
        <Text fontSize="sm" fontWeight="bold" color="gray.500">Prompt</Text>
        <Skeleton isLoaded={imageLoaded} width="100%">
          <Text fontSize="md">{prompt}</Text>
        </Skeleton>
      </VStack>
    </Box>
  );
}

export default ImageCard;