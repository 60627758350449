import React from 'react';
import { Box, VStack, IconButton, Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaHome, FaCompass, FaImage, FaGlobe } from 'react-icons/fa';

function FloatingMenu() {
  return (
    <Box position="fixed" bottom="20px" right="20px" zIndex="1000">
      <VStack spacing={2}>
        <Tooltip label="Home" placement="left">
          <IconButton
            as={Link}
            to="/"
            icon={<FaHome />}
            colorScheme="teal"
            variant="solid"
            borderRadius="full"
          />
        </Tooltip>
        <Tooltip label="Explore Tasks" placement="left">
          <IconButton
            as={Link}
            to="/tasks"
            icon={<FaCompass />}
            colorScheme="teal"
            variant="solid"
            borderRadius="full"
          />
        </Tooltip>
        <Tooltip label="Image Generation" placement="left">
          <IconButton
            as={Link}
            to="/image-generation"
            icon={<FaImage />}
            colorScheme="teal"
            variant="solid"
            borderRadius="full"
          />
        </Tooltip>
        <Tooltip label="Explore Teams" placement="left">
          <IconButton
            as={Link}
            to="/explore"
            icon={<FaGlobe />}
            colorScheme="teal"
            variant="solid"
            borderRadius="full"
          />
        </Tooltip>
      </VStack>
    </Box>
  );
}

export default FloatingMenu;