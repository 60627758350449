import React from 'react';
import { Box, Text, HStack, IconButton, Tooltip } from '@chakra-ui/react';
import { CopyIcon, CheckIcon } from '@chakra-ui/icons';

function CloneCapsule({ numClones, onClone, isSaved }) {
  return (
    <Box
      borderWidth="1px"
      borderColor="gray.600"
      borderRadius="full"
      px={2}
      py={4}
      bg="gray.700"
      height="28px"
      display="flex"
      alignItems="center"
      whiteSpace="nowrap"
    >
      <HStack spacing={2} alignItems="center">
        <Text fontSize="xs" color="gray.400" flexShrink={0}>
          {numClones === 0 ? "No" : numClones} {numClones === 1 ? 'clone' : 'clones'}
        </Text>
        <Tooltip label={isSaved ? "Saved" : "Clone"}>
          <IconButton
            size="xs"
            colorScheme={isSaved ? "green" : "purple"}
            onClick={onClone}
            borderRadius="full"
            icon={isSaved ? <CheckIcon /> : <CopyIcon />}
            isDisabled={isSaved}
            aria-label={isSaved ? "Saved" : "Clone"}
          />
        </Tooltip>
      </HStack>
    </Box>
  );
}

export default CloneCapsule;