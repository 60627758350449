import React, { useEffect } from 'react';
import {
  Box,
  Heading,
  VStack,
  Flex,
  Text,
  Button
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import CrewCard from './CrewCard';
import { useCrews } from '../contexts/CrewsContext';
import { MdGroups, MdExplore } from 'react-icons/md';
import { Player } from '@lottiefiles/react-lottie-player';
import team from '../assets/team.json';

function CrewList() {
  const { crews, fetchCrews } = useCrews();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCrews();
  }, [fetchCrews]);

  const handleExploreTeams = () => {
    navigate('/explore');
  };

  if (crews.length === 0) {
    return (
      <Box 
        borderWidth={1} 
        borderRadius="lg" 
        p={6} 
        bg="lavender" // Light purple pastel background
        boxShadow="sm"
        textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box width="100%" maxWidth="800px" height="auto" mb={4}>
          <Player
            src={team}
            loop
            autoplay
            style={{ width: '100%', height: '100%', maxHeight: '300px' }}
          />
        </Box>
        <Text fontSize="lg" fontWeight="medium" color="gray.600">
          No teams yet!
        </Text>
        <Text fontSize="md" color="gray.500" mt={2}>
          Create one to get started
        </Text>
        <Text fontSize="md" color="gray.500" mt={2}>
          or
        </Text>
        <Button
          leftIcon={<MdExplore />}
          colorScheme="purple"
          variant="outline"
          mt={4}
          borderRadius="full"
          onClick={handleExploreTeams}
        >
          Explore teams
        </Button>
      </Box>
    );
  }

  // Sort crews alphabetically by name
  const sortedCrews = [...crews].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box>
      <Heading size="lg" mb={4} color="gray.600" display="flex" alignItems="center">
        <Flex alignItems="center" mr={2}>
          <MdGroups size="24px" />
        </Flex>
        Your Teams
      </Heading>
      <VStack spacing={4} align="stretch">
        {sortedCrews.map((crew) => (
          <CrewCard key={crew.id} crew={crew} />
        ))}
      </VStack>
    </Box>
  );
}

export default CrewList;