import React, { useState, useEffect, useMemo } from 'react';
import {
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
  Switch,
  Select,
  Tag,
  TagLabel,
  TagCloseButton,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Text,
  ModalHeader
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

const tools = [
  { id: 'webscraper', name: 'Website Scraper' },
  { id: 'codeinterpreter', name: 'Code Interpreter' },
  { id: 'dalle', name: 'DALL-E Image Generator' },
];

const backstoryExamples = [
  "As a seasoned content writer with 5 years of experience in tech blogging, you excel at crafting engaging articles on AI and software development.",
  "With a background in data science and machine learning, you specialize in analyzing complex datasets and creating insightful visualizations.",
  "As an experienced project manager, you excel at breaking down complex tasks and coordinating team efforts for maximum efficiency.",
  "Combining a passion for creative writing with a deep understanding of marketing principles, you craft compelling narratives that resonate with diverse audiences.",
];

const AgentFormModal = ({ isOpen, onClose, onSave, initialAgent }) => {
  const [formData, setFormData] = useState({
    id: '',
    role: '',
    goal: '',
    backstory: '',
    web_search_enabled: false,
    code_execution_enabled: false,
    tools: [],
  });

  const randomBackstoryPlaceholder = useMemo(() => {
    return backstoryExamples[Math.floor(Math.random() * backstoryExamples.length)];
  }, [isOpen]);

  useEffect(() => {
    if (initialAgent) {
      setFormData({
        id: initialAgent.id || '',
        role: initialAgent.role || '',
        goal: initialAgent.goal || '',
        backstory: initialAgent.backstory || '',
        web_search_enabled: initialAgent.web_search_enabled || false,
        code_execution_enabled: initialAgent.code_execution_enabled || false,
        tools: initialAgent.tools || [],
      });
    } else {
      setFormData({
        id: '',
        role: '',
        goal: '',
        backstory: '',
        web_search_enabled: false,
        code_execution_enabled: false,
        tools: [],
      });
    }
  }, [initialAgent, isOpen]);

  const handleInputChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleAddTool = (toolId) => {
    if (toolId && !formData.tools.includes(toolId)) {
      setFormData(prev => ({ ...prev, tools: [...prev.tools, toolId] }));
    }
  };

  const handleRemoveTool = (toolId) => {
    setFormData(prev => ({
      ...prev,
      tools: prev.tools.filter(id => id !== toolId)
    }));
  };

  const handleSave = () => {
    onSave(formData);
    onClose();
  };

  const isFormValid = formData.role.trim() !== '' && formData.goal.trim() !== '';
  const showTools = false;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent borderRadius="xl">
        <ModalHeader bg="purple.100" color="purple.700" borderTopRadius="xl" py={4}>
          {initialAgent ? 'Update minion' : 'Create new minion'}
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Role</FormLabel>
              <Input
                placeholder="What is this minion's role in the team?"
                value={formData.role}
                onChange={(e) => handleInputChange('role', e.target.value)}
                focusBorderColor="purple.100"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Goal</FormLabel>
              <Textarea
                placeholder="What is this minion's goal?"
                value={formData.goal}
                onChange={(e) => handleInputChange('goal', e.target.value)}
                focusBorderColor="purple.100"
                minHeight="40px"
                maxHeight="200px"
                resize="vertical"
                rows={3}
                overflowY="auto"
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                Backstory <Text as="span" color="gray.500" fontWeight="normal">(Optional)</Text>
              </FormLabel>
              <Textarea
                placeholder={randomBackstoryPlaceholder}
                value={formData.backstory}
                onChange={(e) => handleInputChange('backstory', e.target.value)}
                focusBorderColor="purple.100"
                minHeight="40px"
                maxHeight="300px"
                resize="vertical"
                rows={4}
                overflowY="auto"
              />
            </FormControl>
            <FormControl display="flex" alignItems="center" justifyContent="space-between">
              <FormControl display="flex" alignItems="center">
                <FormLabel mb="0" mr={2}>Web search</FormLabel>
                <Switch
                  isChecked={formData.web_search_enabled}
                  onChange={(e) => handleInputChange('web_search_enabled', e.target.checked)}
                  colorScheme="purple"
                />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel mb="0" mr={2}>Code execution</FormLabel>
                <Tooltip label="Allows the minion to execute Python code in a sandboxed environment" hasArrow placement="top">
                  <InfoIcon mr={2} color="gray.500" />
                </Tooltip>
                <Switch
                  isChecked={formData.code_execution_enabled}
                  onChange={(e) => handleInputChange('code_execution_enabled', e.target.checked)}
                  colorScheme="purple"
                />
              </FormControl>
            </FormControl>
            {showTools && (
              <FormControl>
                <FormLabel>Tools</FormLabel>
                <HStack wrap="wrap" spacing={2} mb={2}>
                {formData.tools.map(toolId => {
                  const tool = tools.find(t => t.id === toolId);
                  return (
                    <Tag key={toolId} size="md" borderRadius="full" variant="solid" colorScheme="teal">
                      <TagLabel>{tool.name}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveTool(toolId)} />
                    </Tag>
                  );
                })}
              </HStack>
              <Select 
                placeholder="Add a tool" 
                onChange={(e) => handleAddTool(e.target.value)} 
                value=""
                focusBorderColor="purple.100"
              >
                {tools.map(tool => (
                  <option key={tool.id} value={tool.id} disabled={formData.tools.includes(tool.id)}>
                    {tool.name}
                  </option>
                ))}
                </Select>
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button 
            variant="ghost" 
            mr={3} 
            onClick={onClose}
            _hover={{ bg: 'gray.100', borderRadius: 'full' }}
            borderRadius="full"
          >
            Cancel
          </Button>
          <Button 
            colorScheme="teal" 
            onClick={handleSave} 
            borderRadius="full"
            bg="purple.100"
            color="purple.700"
            _hover={{ bg: 'purple.200' }}
            isDisabled={!isFormValid}
            _disabled={{
              bg: 'gray.100',
              color: 'gray.500',
              cursor: 'not-allowed',
              _hover: { bg: 'gray.100' }
            }}
          >
            {initialAgent ? 'Update' : 'Create'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AgentFormModal;