import React, { useState } from 'react';
import { Box, Flex, Text, IconButton, HStack, useDisclosure, Progress, Tooltip } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import TaskForm from './TaskForm';

const CrewCard = ({ crew }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isHovered, setIsHovered] = useState(false);

  const completedTasks = crew.tasks.filter(task => task.status === 2).length;
  const notStartedTasks = crew.tasks.filter(task => task.status === 0).length;
  const inProgressTasks = crew.tasks.filter(task => task.status === 1).length;
  const totalTasks = crew.tasks.length;
  const completionPercentage = totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;

  const handleCardClick = () => {
    navigate(`/crews/${crew.id}`);
  };

  const handleAddTask = (e) => {
    e.stopPropagation();
    onOpen();
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      borderColor="gray.200"
      p={4}
      bg="white"
      boxShadow="sm"
      transition="all 0.2s"
      _hover={{ boxShadow: 'md' }}
      onClick={handleCardClick}
      cursor="pointer"
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Flex justifyContent="space-between" alignItems="center" mb={3}>
        <Text fontWeight="semibold" fontSize="lg" color="gray.700">
          {crew.name}
        </Text>
        <Text fontSize="sm" color="gray.500">
          {crew.agents.length} minions
        </Text>
      </Flex>
      <Progress value={completionPercentage} size="sm" colorScheme="green" mb={3} borderRadius="full" />
      <Flex justifyContent="space-between" alignItems="center">
        <HStack spacing={4} align="baseline">
          <Text fontSize="sm" color="gray.500">
            <Text as="span" fontWeight="bold" color="teal.500" fontSize="md" mr={1}>
              {completedTasks} / {totalTasks}
            </Text>
            completed
          </Text>
          {inProgressTasks > 0 && (
            <Text fontSize="sm" color="gray.500">
              <Text as="span" fontWeight="bold" color="orange.300" fontSize="md" mr={1}>
                {inProgressTasks}
              </Text>
              in progress
            </Text>
          )}
          {notStartedTasks > 0 && (
            <Text fontSize="sm" color="gray.500">
              <Text as="span" fontWeight="bold" color="gray.500" fontSize="md" mr={1}>
                {notStartedTasks}
              </Text>
              not started
            </Text>
          )}
        </HStack>
        {isHovered && (
          <Tooltip label="Add task" hasArrow>
            <IconButton
              icon={<AddIcon />}
              bg="purple.100"
              color="purple.600"
              size="sm"
              onClick={handleAddTask}
              aria-label="Add task"
              borderRadius="full"
              _hover={{
                bg: "purple.200",
                color: "purple.700"
              }}
            />
          </Tooltip>
        )}
      </Flex>
      <TaskForm
        isOpen={isOpen}
        onClose={onClose}
        crewId={crew.id}
      />
    </Box>
  );
};

export default CrewCard;