import React from 'react';
import { Box, VStack, Text, Heading, SimpleGrid } from '@chakra-ui/react';
import MinionCard from './MinionCard';

const presetAgents = [
  { id: 1, name: 'Web Researcher', role: 'Web Researcher', goal: 'To collect, analyze, and synthesize accurate and relevant information from the web', backstory: 'A former librarian with a passion for digital archiving, now leveraging AI to navigate the vast landscape of online information', web_search_enabled: true },
  { id: 2, name: 'Content Writer', role: 'Content Writer', goal: 'To produce clear, engaging, and well-structured written content on various topics', backstory: 'A seasoned journalist who transitioned into AI to help create content at scale while maintaining human-like creativity and style', web_search_enabled: false },
];

function AgentLibrary({ onSelectAgent, selectedAgentIds, displayGrid }) {
  const availableAgents = presetAgents.filter(agent => !selectedAgentIds.includes(agent.id));

  return (
    <Box>
      <Heading size="lg" mb={4} color="gray.600">Minion Library</Heading>
      {availableAgents.length > 0 ? (
        <SimpleGrid columns={displayGrid ? [1, 2, 3] : 1} spacing={4}>
          {availableAgents.map((agent) => (
            <MinionCard
              key={agent.id}
              agent={agent}
              onEdit={() => onSelectAgent(agent)}
              toggleWebSearch={() => {}}
              onDelete={() => {}}
              isEditable={false}
              isSelectable={true}
            />
          ))}
        </SimpleGrid>
      ) : (
        <Box
          border="2px dashed"
          borderColor="gray.200"
          borderRadius="md"
          p={6}
          textAlign="center"
          minWidth="200px"
        >
          <Text fontSize="sm" color="gray.400">No more minions available in the library</Text>
        </Box>
      )}
    </Box>
  );
}

export default AgentLibrary;