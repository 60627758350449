export const examplePrompts = [
  {
    prompt: "A girl walking through a field, in the style of ethereal trees, dark yellow and azure, majestic, sweeping seascapes, photorealistic representation, graceful balance, wimmelbilder, orange",
    imageUrl: "https://fal.media/files/monkey/NOXewHjtLTf6XaYeqn1C8.png"
  },
  {
    prompt: "A solar system with vibrant-colored planets and exotic atmospheres, depicted in a science fiction style. HD, 8k, vivid colors, HDR effect, color palette, illustration, photo, 3D render, vibrant, portrait photography.",
    imageUrl: "https://fal.media/files/monkey/q22Ye_cv-5U3LNz5rwWDm.png"
  },
  {
    prompt: "A vibrant custom illustration featuring a vintage hot rod, cruising along a sunny street lined with palm trees. The retro cityscape in the background showcases colorful buildings, a neon sign, and a bustling crowd of people. The text 'Summer Vibes' is displayed in a retro, distressed style, with each letter in a different color, exuding a sense of nostalgia. The design is perfect for a t-shirt print, with an isolated white background.",
    imageUrl: "https://fal.media/files/koala/BQ8IK96oMe4DRAcvmN7ba.png"
  },
  // Add more example prompts and associated image URLs
];
