import React, { useState, useCallback } from 'react';
import { Box, Textarea, Button, Text, VStack, HStack, useToast, Grid, GridItem, IconButton, useColorModeValue } from '@chakra-ui/react';
import { CheckIcon, RepeatIcon } from '@chakra-ui/icons';
import { useAuth } from '../contexts/AuthContext';
import API_BASE_URL from '../config';
import { Player } from '@lottiefiles/react-lottie-player';
import drop_loader from '../assets/drop_loader.json';
import ImageCard from './ImageCard';
import ImagePreviewCard from './ImagePreviewCard';
import { updateTask } from '../utils/api';
import { useNavigate } from 'react-router-dom';

function ImageGenerationForm({ taskId }) {
  const [prompt, setPrompt] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editId, setEditId] = useState(null);
  const [iteration, setIteration] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isUpdatingThumbnail, setIsUpdatingThumbnail] = useState(false);
  const { user } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  const handleGenerateImage = async () => {
    if (!prompt) {
      toast({
        title: "Error",
        description: "Please enter a prompt",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const idToken = await user.getIdToken();
      const body = { prompt };
      if (editId && selectedImage) {
        body.task_id = editId;
        body.image_url = selectedImage.url;
      }

      const response = await fetch(`${API_BASE_URL}/generate-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Failed to generate image');
      }

      const data = await response.json();
      const newImage = { 
        url: data.image_url, 
        iteration: data.iteration, 
        prompt: data.prompt,
        userPrompt: prompt
      };
      setGeneratedImages(prevImages => [...prevImages, newImage]);
      setSelectedImage(newImage);
      setEditId(data.task_id);
      setIteration(data.iteration);
      setPrompt('');
    } catch (error) {
      console.error('Error generating image:', error);
      toast({
        title: "Error",
        description: "Failed to generate image",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewImage = () => {
    setPrompt('');
    setSelectedImage(null);
    setEditId(null);
    setIteration(0);
  };

  const handleSelectImage = (image) => {
    setSelectedImage(image);
    setEditId(null);
    setIteration(image.iteration);
    setPrompt(image.prompt);
    setImageLoaded(false);  // Reset imageLoaded when selecting a new image
  };

  const handleImageLoad = useCallback(() => {
    setImageLoaded(true);
  }, []);

  const handleUseImage = async () => {
    if (!taskId || !selectedImage) {
      toast({
        title: "Error",
        description: "No task or image selected",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsUpdatingThumbnail(true);
    try {
      const task = await updateTask(taskId, { thumbnailUrl: selectedImage.url });
      toast({
        title: "Success",
        description: "Task thumbnail updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate(`/crews/${task.crew_id}/tasks/${task.id}`);
    } catch (error) {
      console.error('Error updating task thumbnail:', error);
      toast({
        title: "Error",
        description: "Failed to update task thumbnail",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsUpdatingThumbnail(false);  // Reset the loading state if there's an error
    }
  };

  return (
    <Grid 
      templateColumns={{ base: "1fr", md: generatedImages.length > 0 ? "1fr 2fr" : "1fr" }}
      templateAreas={{
        base: generatedImages.length > 0 ? `"main" "history"` : `"main"`,
        md: generatedImages.length > 0 ? `"history main"` : `"main"`
      }}
      gap={6}
    >
      <GridItem area="main">
        <Box p={6} borderRadius="lg" boxShadow="lg" bg="white">
          <VStack spacing={4}>
            {selectedImage && (
              <VStack spacing={2} align="stretch" width="100%">
                <ImageCard 
                  prompt={selectedImage.prompt} 
                  imageUrl={selectedImage.url}
                  onImageLoad={handleImageLoad}
                />
                {selectedImage.userPrompt !== selectedImage.prompt && (
                  <Text fontSize="sm" color="gray.600">
                    Your input: {selectedImage.userPrompt}
                  </Text>
                )}
              </VStack>
            )}
            
            <Textarea
              placeholder="Enter your image prompt here..."
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              size="lg"
              minHeight="100px"
              maxHeight="200px"
              resize="vertical"
            />
            <HStack spacing={4} width="full" flexWrap="wrap" justifyContent="flex-end">
              <Button
                colorScheme="green"
                onClick={handleGenerateImage}
                isLoading={isLoading}
                loadingText="Generating..."
                size="md"
                borderRadius="full"
                bg="green.100"
                color="green.700"
                _hover={{ bg: "green.200" }}
                flexGrow={1}
                maxWidth={{ base: "100%", sm: "auto" }}
                mb={{ base: 2, sm: 0 }}
              >
                {selectedImage ? 'Edit' : 'Generate'}
              </Button>
              {selectedImage && (
                <IconButton
                  aria-label="Start Over"
                  icon={<RepeatIcon />}
                  onClick={handleNewImage}
                  size="md"
                  colorScheme="yellow"
                  variant="ghost"
                  borderRadius="full"
                  flexGrow={0}
                />
              )}
              {taskId && selectedImage && imageLoaded && (
                <Button
                  onClick={handleUseImage}
                  colorScheme="purple"
                  size="md"
                  borderRadius="full"
                  px={6}
                  fontWeight="bold"
                  isLoading={isUpdatingThumbnail}
                  loadingText="Updating..."
                  bg="purple.100"
                  color="purple.700"
                  leftIcon={<CheckIcon />}
                  _hover={{
                    bg: "purple.200",
                  }}
                  flexGrow={1}
                  maxWidth={{ base: "100%", sm: "auto" }}
                  mb={{ base: 2, sm: 0 }}
                >
                  Use This Image
                </Button>
              )}
            </HStack>
          </VStack>
        </Box>
      </GridItem>
      
      {generatedImages.length > 0 && (
        <GridItem area="history">
          <VStack spacing={4} align="stretch">
            <Text fontSize="xl" fontWeight="bold">History</Text>
            {generatedImages.map((image, index) => (
              <Box
                key={index}
                onClick={() => handleSelectImage(image)}
                cursor="pointer"
              >
                <ImagePreviewCard 
                  prompt={image.prompt} 
                  imageUrl={image.url}
                  isSelected={selectedImage === image}
                />
              </Box>
            ))}
          </VStack>
        </GridItem>
      )}
    </Grid>
  );
}

export default ImageGenerationForm;