import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import API_BASE_URL from '../config';
import { auth } from '../firebase';

const createTask = async ({ crewId, taskData }) => {
  const idToken = await auth.currentUser.getIdToken();
  const { data } = await axios.post(`${API_BASE_URL}/crews/${crewId}/tasks`, taskData, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
  return data;
};

function TaskForm({ isOpen, onClose, onSubmit, crewId }) {
  const [description, setDescription] = useState('');
  const [expectedOutput, setExpectedOutput] = useState('');
  const toast = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (taskData) => createTask({ crewId, taskData }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['crew', crewId]);
        toast({
          title: 'Task added.',
          description: "We've added the task to the crew.",
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setDescription('');
        setExpectedOutput('');
        onClose();
        if (onSubmit) {
          onSubmit(data);
        }
      },
      onError: (error) => {
        console.error('Error adding task:', error);
        toast({
          title: 'Error',
          description: 'Failed to add task.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const taskData = { description, expected_output: expectedOutput };
    mutation.mutate(taskData);
  };

  const isFormValid = description.trim() !== '' && expectedOutput.trim() !== '';

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent borderRadius="xl">
        <ModalHeader bg="purple.100" color="purple.700" borderTopRadius="xl" py={4}>
          Add New Task
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter a detailed description of the task"
                  focusBorderColor="purple.100"
                  borderColor="gray.200"
                  _hover={{ borderColor: "gray.300" }}
                  minHeight="40px"
                  maxHeight="200px"
                  resize="vertical"
                  rows={3}
                  overflowY="auto"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Expected Output</FormLabel>
                <Textarea
                  value={expectedOutput}
                  onChange={(e) => setExpectedOutput(e.target.value)}
                  placeholder="Describe the expected output or result of the task"
                  focusBorderColor="purple.100"
                  borderColor="gray.200"
                  _hover={{ borderColor: "gray.300" }}
                  minHeight="40px"
                  maxHeight="200px"
                  resize="vertical"
                  rows={3}
                  overflowY="auto"
                />
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button 
            variant="ghost" 
            mr={3} 
            onClick={onClose}
            _hover={{ bg: 'gray.100', borderRadius: 'full' }}
            borderRadius="full"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            borderRadius="full"
            bg="purple.100"
            color="purple.700"
            _hover={{ bg: 'purple.200' }}
            isDisabled={!isFormValid}
            isLoading={mutation.isLoading}
            _disabled={{
              bg: 'gray.100',
              color: 'gray.500',
              cursor: 'not-allowed',
              _hover: { bg: 'gray.100' }
            }}
          >
            Add Task
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TaskForm;