import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Box,
  Text,
  Heading,
  useColorModeValue,
  Flex,
  Spinner,
  InfoIcon,
} from '@chakra-ui/react';

function TaskDetailsModal({ isOpen, onClose, task, executeTask }) {
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const [localTask, setLocalTask] = useState(task);

  useEffect(() => {
    setLocalTask(task);
  }, [task]);

  const handleExecuteTask = async () => {
    setLocalTask(prevTask => ({ ...prevTask, status: 1 }));
    await executeTask(localTask.id);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent maxWidth="90vw" borderRadius="xl" overflow="hidden" bg={bgColor}>
        <ModalHeader bg="purple.100" color="purple.700" borderTopRadius="xl" py={4}>
          Task Details
        </ModalHeader>
        <ModalCloseButton color="purple.700" />
        <ModalBody>
          {localTask ? (
            <VStack align="stretch" spacing={8} py={4}>
              <Box
                borderWidth="1px"
                borderRadius="lg"
                borderColor={borderColor}
                p={6}
                boxShadow="sm"
                transition="all 0.2s"
                _hover={{ boxShadow: 'md' }}
                bg={bgColor}
              >
                <VStack align="stretch" spacing={4}>
                  <Box>
                    <Heading as="h2" size="lg" color={textColor} mb={2}>Task description</Heading>
                    <Text color={textColor} fontSize="md">{localTask.description}</Text>
                  </Box>
                  <Box>
                    <Heading as="h2" size="lg" color={textColor} mb={2}>Expected output</Heading>
                    <Text color={textColor} fontSize="md">{localTask.expected_output}</Text>
                  </Box>
                </VStack>
              </Box>

              {localTask.status === 1 && (
                <Flex alignItems="center" justifyContent="center">
                  <Spinner color="purple.500" size="md" />
                  <Text fontWeight="semibold" color={textColor} ml={2}>
                    Your team of minions is churning away at your task
                  </Text>
                </Flex>
              )}
            </VStack>
          ) : (
            <Text>No task details available.</Text>
          )}
        </ModalBody>
        <ModalFooter borderColor={borderColor}>
          <Button 
            variant="ghost" 
            onClick={onClose} 
            borderRadius="full"
            mr={3}
          >
            Close
          </Button>
          {localTask && localTask.status !== 1 && (
            <Button 
              onClick={handleExecuteTask}
              borderRadius="full"
              bg="purple.100"
              color="purple.700"
              _hover={{ bg: 'purple.200' }}
            >
              Start task
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TaskDetailsModal;